<template>
  <div class="w-full">
    <ValidationObserver ref="formProcurementApproval">
      <div class="relative flex flex-wrap items-center w-full gap-6 my-6"  >
        <CancelCreateApproval :edit="edit" @click="changeIsCreateProcurementApproval" class="flex-none" />
        <h4 class="flex flex-wrap justify-center flex-auto text-lg font-medium text-gray-darkest">{{ title }}</h4>
        <button @click="expandCollapseAll" class="flex items-center flex-none underline cursor-pointer text-tertiary hover:text-tertiary-dark" >{{messageCollapseAll}}</button>
      </div>
      <Information :editRequester="editRequester" :readOnly="editRequester" @changeCollapse="changeCollapse('information')" :collapse="collapse" />
      <NegotiationResult :readOnly="editRequester" @changeCollapse="changeCollapse('negoResults')" :collapse="collapse" />
      <div class="w-full pt-6 mt-6 border-t-2 border-gray-lightest">
        <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse('vendorCandidateScoring')" >
          <div class="flex items-center">
            <span class="mr-4 icon-list"></span>
            <h5 class="text-lg font-medium text-gray-darkest">VENDOR CANDIDATES & SCORING &nbsp;</h5>
            <span v-if="!editRequester" class="text-error">*</span>
          </div>
          <div>
            <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse.vendorCandidateScoring ? 'rotate-180' : 'rotate-0'"></p>
          </div>
        </div>
       <div v-show="collapse.vendorCandidateScoring">
          <VendorCandidateScoringTable :edit="!editRequester" />
          <div class="col-span-2 py-3.5">
            <ValidationProvider name="Reason for Vendor Selection"
              rules="required"
              v-slot="{ errors }">
              <TextareaGista label="Reason for Vendor Selection"
                placeholder="Leave a comment here"
                v-model="vendorSelectionReason"
                :mandatory="true"
                :disabled="editRequester"
                :error="!!errors[0] || !!errorMaxLength['Reason for Vendor Selection']">
                <template #message>
                  <p>{{ errors[0] || errorMaxLength['Reason for Vendor Selection'] }}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="w-full pt-6 mt-6 border-t-2 border-gray-lightest">
        <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse('projectApproval')">
          <div class="flex items-center">
            <span class="mr-4 icon-list"></span>
            <h5 class="text-lg font-medium text-gray-darkest">PROJECT PROCUREMENT APPROVAL</h5>
          </div>
          <div>
            <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse.projectApproval ? 'rotate-180' : 'rotate-0'"></p>
          </div>
        </div>
      </div>
      <ProjectProcurementApprovalTable v-show="collapse.projectApproval" />
      <div class="w-full pt-6 mt-6 border-t-2 border-gray-lightest">
        <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse('costBudget')">
          <div class="flex items-center">
            <span class="mr-4 icon-list"></span>
            <h5 class="text-lg font-medium text-gray-darkest">COST & BUDGET INFORMATION &nbsp;</h5>
            <span v-if="!editRequester" class="text-error">*</span>
          </div>
          <div>
            <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse.costBudget ? 'rotate-180' : 'rotate-0'"></p>
          </div>
        </div>
      </div>
      <CostBudgetInformation :edit="!editRequester" v-show="collapse.costBudget"/>
      <Recommendation :readOnly="editRequester" @changeCollapse="changeCollapse" :collapse="collapse" />
      <div class="pt-6 mt-6 border-t-2 border-gray-lightest ">
        <div class="flex justify-between" :class="projectDetailContainerWidth > 640 ? '' : 'flex-col gap-6 w-full'">
          <SaveAsDraftApprover @click="saveAsDraft" @clickToggle="clickChangeIsOpenSaveAsDraft($event)" :isOpen="isOpenSaveAsDraft"/>
          <div class="gap-6" :class="projectDetailContainerWidth > 640 ? 'grid grid-cols-2' : 'flex flex-col'">
            <ButtonGista type="secondary" color="primary" @click="clickPreviewBan">Preview</ButtonGista>
            <SubmitProcurementApproval v-if="!editRequester" @click="submitApproval" :edit="edit" :isOpen="isOpenSubmit" @clickToggle="clickChangeIsOpenSubmit"/>
            <SubmitChangeApprovals v-else @click="submitChangeApprovals" :isOpen="isOpenChangeApprovals" @clickToggle="clickChangeIsOpenChangeApprovals"/>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>

import NegotiationResult from '@/core/components/rfq/procurementApproval/NegotiationResult.vue'
import VendorCandidateScoringTable from '@/core/components/rfq/procurementApproval/VendorCandidateScoringTable.vue'
import Recommendation from '@/core/components/rfq/procurementApproval/Recommendation.vue'
import Information from '@/core/components/rfq/procurementApproval/Information.vue'
import SaveAsDraftApprover from '@/core/components/rfq/procurementApproval/SaveAsDraftApprover.vue'
import SubmitProcurementApproval from '@/core/components/rfq/procurementApproval/SubmitProcurementApproval.vue'
import SubmitChangeApprovals from '@/core/components/rfq/procurementApproval/SubmitChangeApprovals.vue'
import CancelCreateApproval from '@/core/components/rfq/procurementApproval/CancelCreateApproval.vue'
import ProjectProcurementApprovalTable from '@/core/components/rfq/procurementApproval/ProjectProcurementApprovalTable.vue'
import CostBudgetInformation from '@/core/components/rfq/procurementApproval/CostBudgetInformation.vue'
import { MESSAGE_SUCCESS_CREATE_APPROVAL, MESSAGE_SUCCESS_APPROVAL_CHANGE_APPROVER } from '@/core/constant/successMessage'
import { MESSAGE_POPUP_VALIDATION_SUBMIT } from '@/core/constant/errorMessage.js'

export default {
  name: "CreateProcurementApproval",
  props: ['isContinueDraft'],
  data() {
    return {
      messageCollapseAll: 'Collapse All',
      collapse: {
        information: true,
        negoResults: true,
        vendorCandidateScoring: true,
        projectApproval: true,
        costBudget: true,
        recommendation: true,
        checker: true,
        approver: true
      },
      edit: false,
      vendorSelectionReason: '',
      isOpenSaveAsDraft: false,
      isOpenSubmit: false,
      isOpenChangeApprovals: false
    }
  },
  watch: {
    vendorSelectionReason() {
      const MAX_CHARACTER = 1000
      if(this.vendorSelectionReason.length > MAX_CHARACTER) this.errorMaxLength['Reason for Vendor Selection'] = 'Kolom Reason for Vendor Selection Maksimal 1000 Karakter'
      else this.errorMaxLength['Reason for Vendor Selection'] = ''
    },
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    editRequester: {
      get() {
        return this.$store.state.procurementApprovalRfq.editRequester
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_EDIT_REQUESTER', value)
      }
    },
    title() {
      if(this.editRequester) return 'Change Approvers'
      return `${this.edit ? 'Edit' : 'Create'} Procurement Approval`
    },
    errorMaxLength: {
      get() {
        return this.$store.state.procurementApprovalRfq.errorMaxLength
      },
      set(val) {
        this.$store.commit('procurementApprovalRfq/SET_ERROR_MAX_LENGTH', val)
      }
    }
  },
  methods:{
    //function collapse/extend
    changeCollapse(key) {
      this.collapse[key] = !this.collapse[key]
      if (Object.values(this.collapse).every(el => el)) this.messageCollapseAll = 'Collapse All'
      else if (Object.values(this.collapse).every(el => !el)) this.messageCollapseAll = 'Extend All'
    },
    expandCollapseAll() {
      for (const key in this.collapse) {
        if (this.messageCollapseAll === 'Extend All') {
          this.collapse[key] = true
        } else {
          this.collapse[key] = false
        }
      }
      if (this.messageCollapseAll === 'Collapse All') this.messageCollapseAll = 'Extend All'
      else this.messageCollapseAll = 'Collapse All'
    },
    async saveAsDraft() {
      try {
        this.modal.modalLoading = true
        this.$refs.formProcurementApproval.reset();
        const checkErrorLength = await this.$store.dispatch('procurementApprovalRfq/checkErrorLength')
        const MESSAGE_ERROR_LENGTH = await this.$store.dispatch('procurementApprovalRfq/messageErrorLength')
        const checkErrorQtyOrder = await this.$store.dispatch('procurementApprovalRfq/checkErrorQtyOrder')
        const MESSAGE_ERROR_QTY_ORDER = !checkErrorQtyOrder? ['Qty Order']: []
        const checkErrorCostBudgetInformation = await this.$store.dispatch('procurementApprovalRfq/checkErrorCostBudgetInformation', { isSubmit: false })
        const MESSAGE_ERROR_COST_BUDGET_INFORMATION = !checkErrorCostBudgetInformation? ['Cost & Budget Information'] : []
        console.log(checkErrorCostBudgetInformation);
        if (!checkErrorLength || !checkErrorQtyOrder || !checkErrorCostBudgetInformation) {
          this.modal.modalLoading = false
          const message = [ ...MESSAGE_ERROR_LENGTH, ...MESSAGE_ERROR_QTY_ORDER, ...MESSAGE_ERROR_COST_BUDGET_INFORMATION ].join(', ')
          this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + message }
          this.clickChangeIsOpenSaveAsDraft(false)
          return
        }
        this.$emit('changeIsCreateProcurementApproval')
        this.$emit('changeIsContinueDraft')
        this.modal.modalLoading = false

      } catch (e) {
        console.log(e)
        this.modal.modalLoading = false
      }
    },
    submitChangeApprovals() {
      this.$store.dispatch('procurementApprovalRfq/changeApprovals')
      this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_APPROVAL_CHANGE_APPROVER }
    },
    messageErrorValidate() {
      const errorVeeValidate = this.$refs.formProcurementApproval.errors
      return Object.keys(errorVeeValidate).filter(e => errorVeeValidate[e].length > 0)
      // const filterErrorCustom = Object.keys(this.error).filter(e => this.error[e] === true)
      // return [ ...filterError ].join(', ')
    },
    async submitApproval() {
      try {
        this.modal.modalLoading = true
			  const success = await this.$refs.formProcurementApproval.validate()
        const MESSAGE_ERROR_VALIDATE = this.messageErrorValidate()
        const checkErrorLength = await this.$store.dispatch('procurementApprovalRfq/checkErrorLength')
        const MESSAGE_ERROR_LENGTH = await this.$store.dispatch('procurementApprovalRfq/messageErrorLength')
        const checkErrorQtyOrder = await this.$store.dispatch('procurementApprovalRfq/checkErrorQtyOrder')
        const MESSAGE_ERROR_QTY_ORDER = !checkErrorQtyOrder? ['Qty Order']: []
        const checkErrorRecommendation = await this.$store.dispatch('procurementApprovalRfq/checkErrorRecommendation')
        const MESSAGE_ERROR_RECOMMENDATION = await this.$store.dispatch('procurementApprovalRfq/messageErrorRecommendation')
        const checkErrorCandidateScoring = await this.$store.dispatch('procurementApprovalRfq/checkValidationVendorCandidateScoring') 
        const checkErrorCostBudgetInformation = await this.$store.dispatch('procurementApprovalRfq/checkErrorCostBudgetInformation', { isSubmit: true })
        const MESSAGE_ERROR_COST_BUDGET_INFORMATION = !checkErrorCostBudgetInformation? ['Cost & Budget Information'] : []
        if (!success || !checkErrorLength || !checkErrorRecommendation || checkErrorCandidateScoring.value || !checkErrorCostBudgetInformation) {
          console.log('masuk');
          this.modal.modalLoading = false
          if(checkErrorCandidateScoring.message) {
            console.log('masuk 2');
            this.modal.modalErrorValidate = { value: true, message: checkErrorCandidateScoring }
          } else {
            console.log('masuk 3');
            const message = [ ...MESSAGE_ERROR_VALIDATE, ...MESSAGE_ERROR_LENGTH, ...MESSAGE_ERROR_QTY_ORDER, ...MESSAGE_ERROR_COST_BUDGET_INFORMATION, ...MESSAGE_ERROR_RECOMMENDATION].join(', ')
            this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + message }
            // const message = MESSAGE_ERROR_VALIDATE && MESSAGE_ERROR_LENGTH ? `, ${MESSAGE_ERROR_LENGTH}` : MESSAGE_ERROR_LENGTH
            // this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + MESSAGE_ERROR_VALIDATE + message + (MESSAGE_ERROR_RECOMMENDATION && `, ${MESSAGE_ERROR_RECOMMENDATION}`) }
          }
          this.clickChangeIsOpenSubmit()
          return
          
				}
        // note recommendation: send only filled obj to api
        if (this.isContinueDraft) {
          this.$emit('changeIsCreateProcurementApproval')
          this.$emit('changeIsContinueDraft')
        } else {
          this.$emit('changeSubmittedProcurementApproval')
        }
        this.modal.modalLoading = false
        this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_CREATE_APPROVAL }
      } catch (e) {
			  this.modal.modalLoading = false
        console.log(e)
      }
    },
    changeIsCreateProcurementApproval() {
      this.$emit('changeIsCreateProcurementApproval')
    },
    changeSubmittedProcurementApproval() {
      this.$emit('changeSubmittedProcurementApproval')
    },
    clickPreviewBan() {
      console.log('clicked')
    },
    clickChangeIsOpenSaveAsDraft(val) {
      this.isOpenSaveAsDraft = val
    },
    clickChangeIsOpenSubmit() {
      this.isOpenSubmit = !this.isOpenSubmit
    },
    clickChangeIsOpenChangeApprovals() {
      this.isOpenChangeApprovals = !this.isOpenChangeApprovals
    },
  },
  mounted() {
    if(this.editRequester) {
      this.collapse = {
        information: true,
        negoResults: false,
        vendorCandidateScoring: false,
        projectApproval: false,
        costBudget: false,
        recommendation: true,
        checker: true,
        approver: true
      }
    }
  },
  components:{
    Information,
    NegotiationResult,
    VendorCandidateScoringTable,
    Recommendation,
    SaveAsDraftApprover,
    ProjectProcurementApprovalTable,
    CostBudgetInformation,
    SubmitChangeApprovals,
    SubmitProcurementApproval,
    CancelCreateApproval
  }
}
</script>

