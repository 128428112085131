<template>
	<div class="">
		<div class="w-full overflow-x-auto max-h-custom">
			<div class="w-full">
				<table class="relative w-full border-collapse" aria-describedby="negotiationRfqTable">
					<thead class="sticky top-0 z-20 h-10 py-1 bg-white">
						<tr class="truncate">
							<th class="p-0" scope="col">
								<div class="h-10 border-b-2 border-primary"></div>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">No</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">ID Product</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Type</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Product/Service</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Sub Product</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Description</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Location</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Qty</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">UoM</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Period</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Currency</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 text-gray-darkest border-primary">Monthly Cost</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 text-gray-darkest border-primary">Unit Price</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 text-gray-darkest border-primary">Total Unit Price</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 text-gray-darkest border-primary">Disc %</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 text-gray-darkest border-primary">Disc Price</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 pr-8 text-sm font-medium text-center border-b-2 text-gray-darkest border-primary">Final Total Price</h5>
							</th>
						</tr>
					</thead>
					<tbody v-for="n,i in negotiationDetail.offers" :key="i">
						<template >
							<tr class="sticky z-10 h-10 py-1 cursor-pointer top-10 bg-primary-lightest" 
								@click="toggleCollapse(i)">
								<td class="w-max py-2 px-2.5 cursor-pointer" colspan="1">
									<span v-if="!collapse[i]"
										class="mr-3 text-base font-bold cursor-pointer icon-plus text-gray"></span>
									<span v-else
										class="mr-3 text-base font-bold cursor-pointer icon-minus text-gray"></span>
								</td>
								<td class="relative" colspan="17">
									<div class="absolute top-0 flex items-center h-10">
										<h5 class="mr-4 text-sm text-gray-darkest">{{n.title}}</h5>
										<TooltipSolutip :dataTitle="`Uploaded Date: ${n.dateUploaded}`">
											<p class="mr-4 text-lg icon-info text-gray"></p>
										</TooltipSolutip>
										
										<button-extra-small-solutip
											:color="showContinueDraft(n) ? 'success' : 'secondary'"
											@click.stop="clickEditDetail(n, i)" 
											:disabled="negotiationDetail?.isCanceled"
											class="mr-4"
											v-if="menuRfqNegotiationOfferSave?.canCreate && showEdit(n, i)"
											>{{showContinueDraft(n) ? 'Continue Draft' : 'Edit Detail'}}
										</button-extra-small-solutip>
										<button-extra-small-solutip
											color="secondary"
											@click.stop="clickEdit(i)" 
											class="mr-4"
											:disabled="negotiationDetail?.isCanceled"
											v-if="menuRfqNegotiationOfferSave?.canCreate && showEditOffer"
											>Edit Offer
										</button-extra-small-solutip>
										<div v-if="n.isBanFptnSubmitted === false"	class="flex items-center">
											<button-extra-small-solutip 
												color="error" 
												@click.stop="openModalDelete(i)"
												v-if="menuRfqNegotiationOfferDelete?.canDelete && notContinueDraft && !n.isDraftAfterSubmit && !isRejected"
												:disabled="negotiationDetail?.isCanceled"
												>Delete
											</button-extra-small-solutip>
										</div>
										
										<div v-if="n.isBanFptnSubmitted === true" class="flex gap-4">
											<button-extra-small-solutip
												v-if="menuRfqNegotiationBan?.canRead && n.banType === 'BAN' && !showReviseOffer && !showDetailFPTN"
												color="tertiary" 
												@click.stop="clickViewDetailBan(n, i)"
											>View 
											</button-extra-small-solutip>
											<button-extra-small-solutip
												v-if="menuRfqNegotiationFptn?.canRead && n.banType === 'FPTN' && !showReviseOffer && !showDetailFPTN"
												color="tertiary" 
												@click.stop="clickViewFPTN(n)"
												>View FPTN
											</button-extra-small-solutip>
										</div>
									</div>
								</td>
							</tr>
							<template v-for="(item, j) in n.offerDetails">
								<tr v-show="collapse[i]" class="h-10 truncate" v-if="item" :key="j">
									<td class="w-max flex items-center px-2.5 ">
										<span class="mr-3 text-base font-bold text-white icon-plus"></span>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-gray-darkest">{{ item.no ?? '-' }}</h5>
									</td>
									<td>
										<h5 v-if="item.productId?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.productId.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.productId)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.productId }}</h5>
									</td>
									<td>
										<h5 v-if="item.jenis?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.jenis.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.jenis)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.jenis }}</h5>
									</td>
									<td>
										<h5 v-if="item.productLayanan?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.productLayanan.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.productLayanan)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.productLayanan }}</h5>
									</td>
									<td>
										<h5 v-if="item.subProduct?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.subProduct.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.subProduct)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.subProduct }}</h5>
									</td>
									<td>
										<h5 v-if="item.description?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.description.slice(0,20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.description)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.description }}</h5>
									</td>
									<td>
										<h5 v-if="item.location?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.location.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.location)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.location }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-gray-darkest">{{ formatCurrency(item.qty, item.currency) }}</h5>
									</td>
									<td>
										<h5 v-if="item.uom?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.uom.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.uom)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.uom }}</h5>
									</td>
									<td>
										<h5 v-if="item.period?.length > maxLength" class="pr-8 text-sm text-gray-darkest">{{ item.period.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.period)">Read More</span></h5>
										<h5 v-else class="pr-8 text-sm text-gray-darkest">{{ item.period }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-gray-darkest">{{ item.currency }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.biayaPerBulan, item.currency) }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.unitPrice, item.currency) }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.totalUnitPrice, item.currency) }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.discount, item.currency) }}%</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.discountPrice, item.currency) }}</h5>
									</td>
									<td>
										<h5 class="pr-8 text-sm text-right text-gray-darkest">{{ formatCurrency(item.finalTotalPrice, item.currency) }}</h5>
									</td>
								</tr>
							</template>
							<template v-if="n.costType !== RATE_COST">
								<tr v-show="collapse[i]" class="bg-gradient-to-t from-primary-lightest to-white">
									<td class="p-0"><div class="h-10 border-b-2 border-primary"></div></td>
									<td class="p-0">
										<div class="flex items-center h-10 border-b-2 border-primary">
											<span class="text-base icon-corner-down-right"></span>
										</div>
									</td>
									<td class="p-0" colspan="17">
										<div class="flex items-center h-10 border-b-2 border-primary">
											<p class="mr-12 text-sm font-medium text-gray-darkest">Total Price: <span class="text-sm font-semibold text-primary">{{ formatCurrency(n.totalPrice, totalCurrency(n)) }}</span></p>
											<p v-if="!vatIncludedChecker(n?.taxType)" class="mr-12 text-sm font-medium text-gray-darkest">VAT ({{ n?.taxTypePercentage }}%): <span class="text-sm font-semibold text-primary">{{ formatCurrency(n.ppn, totalCurrency(n)) }}</span></p>
											<p v-else class="mr-12 text-sm font-medium text-gray-darkest">{{ n?.taxType }}</p>
											<p class="mr-12 text-sm font-medium text-gray-darkest">Total Price + VAT: <span class="text-sm font-semibold text-primary">{{ formatCurrency(n.grandTotal, totalCurrency(n)) }}</span></p>
										</div>
									</td>
								</tr>
							</template>
						</template>
					</tbody>
				</table>
				<ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="modalText"/>
				<ModalConfirm 
					title="Hapus?" 
					message="Semua informasi akan dihapus, apakah Anda yakin?" 
					icon="rejected" 
					textButton="Delete" 
					:isOpen="isOpenDelete" 
					@changeIsOpen="changeIsOpen($event)"
					@click="clickDelete"
					colorButton="error"></ModalConfirm>
			</div>
		</div>
		<div v-if="negotiationDetail?.offers?.length === 0" class="flex items-center justify-center w-full pt-6">
			<h4 class="text-sm font-medium text-warning-darkest">
				No data. Please upload the initial offer from vendor.
			</h4>
		</div>
	</div>
</template>

<script>
import { formatCurrency } from '@/core/components/helpers/formatRupiah'
import { vatIncludedChecker } from '@/core/components/helpers'
import ButtonExtraSmallSolutip from '@/core/components/custom/ButtonExtraSmall.vue'
import TooltipSolutip from '@/core/components/custom/Tooltip.vue'
import ModalReadMore from '@/core/components/modal/ReadMore.vue'
import ModalConfirm from '@/core/components/modal/Confirm.vue'
import { QUOTATIONNEGOTIATION } from '@/core/constant/routeName'

export default {
	name: 'NegotiationTable',
	data() {
		return {
			collapse: [],
			modalText: '',
			readMoreShow: false,
			RATE_COST: 'RATE_COST',
			isOpenDelete: false,
			indexDelete: 0,
			maxLength: 34
		}
	},
	computed: {
		negotiationDetail() {
			return this.$store.state.negotiationRfq.negotiationDetail
		},
		showReviseOffer() {
			return this.$store.state.negotiationRfq.showReviseOffer
		},
    showDetailFPTN() {
			return this.$store.state.negotiationRfq.showDetailFPTN
		},
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
		menuRfqNegotiationOfferSave() {
			return this.$store.getters['dashboard/menuRfqNegotiationOfferSave']
		},
		menuRfqNegotiationOfferDelete() {
			return this.$store.getters['dashboard/menuRfqNegotiationOfferDelete']
		},
		menuRfqNegotiationFptn() {
			return this.$store.getters['dashboard/menuRfqNegotiationFptn']
		},
		menuRfqNegotiationBan() {
			return this.$store.getters['dashboard/menuRfqNegotiationBan']
		},
		isRejected() {
			const data = this.negotiationDetail?.logApprovalStatus
			return data ? data.toLowerCase() === 'rejected' : false
		},
		showEditOffer() {
			return this.negotiationDetail.negotiationStatus !== 'FINAL_DEAL' || this.negotiationDetail?.isEnableRenegotiation
		},
		notContinueDraft() {
			if (this.negotiationDetail.isEnableContinueDraftBanFptnReviseOffer || this.negotiationDetail.isEnableContinueDraftBanFptnDeal) {
				return false
			}
			return true
		},
	},
	watch: {
		negotiationDetail() {
			this.collapse = this.negotiationDetail?.offers?.map((e,i) => (i === this.negotiationDetail?.offers?.length - 1))
		}
	},
	methods: {
		formatCurrency,
		vatIncludedChecker,
		toggleCollapse(index) {
			this.collapse = this.collapse.map((e,i) => (i === index? !e : e))
		},
		openModalDelete(index) {
			this.isOpenDelete = true
			this.indexDelete = index
		},
		changeIsOpen(val) {
			this.isOpenDelete = val
		},
		closeModal() {
			this.readMoreShow = false
		},
		readMore(text) {
			this.readMoreShow = true;
			this.modalText = text
		},
		clickEdit(i) {
			this.$store.commit('negotiationRfq/SET_SHOW_EDIT_OFFER', i)
		},
		clickViewFPTN(n) {
			this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_FPTN', true)
			this.$router.push({ name: QUOTATIONNEGOTIATION, query: { tab: 'BAN', fptn: n.negotiationId }})
		},
		clickViewDetailBan(n, index) {
			this.$store.commit('negotiationRfq/SET_BAN_NEGOTIATION_ID', this.negotiationDetail.offers[index].negotiationId)
			if (n.title.includes('Final Deal')) {
				this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_BAN', true)
				this.$router.push({ name: QUOTATIONNEGOTIATION, query: { tab: 'BAN', negotiation: n.negotiationId }})
			} else {
				this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_OFFER', true)
				this.$router.push({ name: QUOTATIONNEGOTIATION, query: { tab: 'BAN', detail: 'offer', negotiation: n.negotiationId }})
			}
    },
		async clickDelete() {
			console.log('delete Offer', this.negotiationDetail);
			try {
				this.modal.modalLoading = true
				const response = await this.$store.dispatch('negotiationRfq/deleteNegotiationOffer', { negotiationId: this.negotiationDetail.offers[this.indexDelete].negotiationId })
				if (response?.status < 300 && response?.status >= 200) {
					this.modal.modalLoading = false
					this.changeIsOpen(false)
					this.$store.dispatch('negotiationRfq/getNegotiationList',{rfqId:this.$route.params.quotationId})
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
			}
		},
		totalCurrency(n) {
			return n.offerDetails?.[0]?.currency
		},
		clickEditDetail(n, idx) {
			if(n.title.includes('Final Deal') || !this.negotiationDetail.isNegotiationRequired) {
				if(!this.negotiationDetail.isEnableContinueDraftBanFptnDeal) this.$emit('clickEdit')
				else this.$emit('clickContDraft', true)
			} else {
				this.$store.commit('negotiationRfq/SET_SELECTED_IDX_OFFER', idx)
				if(this.showContinueDraft(n)) this.$emit('reviseDraft', n.lastModifiedDateBanFptn)
				else this.$emit('clickRevise')
			}
		},
		showContinueDraft(n) {
			return !n.isBanFptnSubmitted
		},
		showEdit(n, i) {
			const negotiationDetails = this.negotiationDetail
			if (!negotiationDetails.isNegotiationRequired && negotiationDetails.negotiationStatus !== 'FINAL_DEAL') return false
			const condition  = negotiationDetails.isEnableUploadOffer ? true : i !== negotiationDetails.offers.length - 1
			return ((negotiationDetails.negotiationStatus !== 'FINAL_DEAL' && n.isBanFptnDeal === false && condition )|| negotiationDetails?.isEnableRenegotiation)
		},
	},
	mounted() {
		this.collapse = this.negotiationDetail?.offers?.map((e,i) => (i === this.negotiationDetail?.offers?.length - 1))
	},
	components:{
		ButtonExtraSmallSolutip,
		TooltipSolutip,
		ModalReadMore,
		ModalConfirm
	}


}
</script>

<style scoped>
.max-h-custom {
	max-height: 24rem;
}
.min-w-64 {
	min-width: 16rem;
}
</style>