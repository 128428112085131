<template>
	<div class="">
		<div class="w-full overflow-auto max-h-custom">
			<div class="w-full">
				<table class="relative w-full border-collapse" aria-describedby="vendorCandidateScoring">
					<thead class="sticky top-0 z-20 h-16 py-1 bg-white">
						<tr class="">
							<th class="p-0" scope="col">
								<h5 class="flex items-center w-48 h-16 pl-2 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Vendor Candidate</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-16 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Status</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-16 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Scoring Result</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-16 pr-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Scoring Escrow</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-16 px-8 text-sm font-medium text-center border-b-2 text-gray-darkest border-primary">BAN</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-16 px-8 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Due Diligence</h5>
							</th>
						</tr>
					</thead>
					<tbody v-for="(n,i) in vendorCandidateScoring" :key="i">
						<tr :class="`${n.localPartner ? '' : 'border-b border-gray-lightest'}`">
							<td class="pl-2">
								<div class="pr-8" :class="n.localPartner ? 'pt-4' : ''">
									<h5 class="text-sm text-tertiary">Vendor #{{ n.vendorSequence }}</h5>
									<h5 class="text-sm text-gray-darkest">{{ n.vendorName}}</h5>
								</div>
							</td>
							<td>
								<div v-if="n.localPartner"></div>
								<div v-else-if="edit && !n.nonSelect" class="pr-8 py-3.5 min-w-status">
									<SelectGista
										:options="['Terpilih', 'Tidak Terpilih']"
										@change="changeStatus($event, i)"
										size="small"
										:value="n.status"
                                        :error="errorVendorCandidate?.[i]?.err"
									>
										<template #selected>
											{{ n.status || '(Pilih Status)' }}
										</template>
									</SelectGista>
                                    <div v-if="errorVendorCandidate?.[i]?.err">
                                        <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
                                    </div>
								</div>
								<h5 v-else class="py-6 pr-8 text-sm truncate text-gray-darkest">{{ n.status }}</h5>
							</td>
							<td>
								<h5 class="pr-8 text-sm text-gray-darkest">{{ n.scoringResult }}</h5>
							</td>
							<td>
								<h5 class="pr-8 text-sm text-gray-darkest">{{  n.scoringEscrow }}</h5>
							</td>
							<td>
								<div class="flex items-center justify-center">
									<div v-if="n.localPartner"></div>
									<button-gista
										@click="clickViewBAN(i)"
										size="small"
										type="secondary"
										color="primary"
										customClass="w-16 py-1.5"
										v-else-if="n.viewBan"
										>
										<span class="icon-eye"></span>
									</button-gista>
									<button-gista
										size="small"
										type="secondary"
										color="gray"
										disabled
										customClass="w-16 py-1.5 text-xs"
										v-else
										>
										<p class="text-xs">N/A</p>
									</button-gista>
								</div>
							</td>
							<td>
								<div class="flex items-center justify-center">
									<button-gista
										@click="clickViewDueDiligence(i)"
										size="small"
										type="secondary"
										color="primary"
										customClass="w-16 py-1.5"
										>
										<span class="icon-eye"></span>
									</button-gista>
								</div>
							</td>
						</tr>
						<tr :class="`${idx === n.localPartner?.length - 1 ? 'border-b border-gray-lightest' : ''}`" v-for="(local, idx) in n.localPartner" :key="idx">
							<td class="pl-2">
								<div class="pr-8 pl-3.5 border-l border-gray-light">
									<h5 class="text-sm text-tertiary">Local Partner #{{ idx + 1 }}</h5>
									<h5 class="text-sm text-gray-darkest">{{ local.vendorName }}</h5>
								</div>
							</td>
							<td>
								<div v-if="edit && !local.nonSelect" class="pr-8 py-3.5 min-w-status">
									<SelectGista
										:options="['Terpilih', 'Tidak Terpilih']"
										@change="changeStatusLocal($event, i, idx)"
										size="small"
										:value="local.status"
                                        :error="errorVendorCandidate?.[i]?.local[idx].err"
									>
										<template #selected>
											{{ local.status || '(Pilih Status)' }}
										</template>
									</SelectGista>
                                    <div v-if="errorVendorCandidate?.[i]?.local[idx].err">
                                        <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
                                    </div>
								</div>
								<h5 v-else class="py-6 pr-8 text-sm truncate text-gray-darkest">{{ local.status }}</h5>
							</td>
							<td></td>
							<td></td>
							<td>
								<div class="flex items-center justify-center">
									<button-gista
										@click="clickViewBAN(i)"
										size="small"
										type="secondary"
										color="primary"
										customClass="w-16 py-1.5"
										v-if="local.viewBan"
										>
										<span class="icon-eye"></span>
									</button-gista>
									<button-gista
										size="small"
										type="secondary"
										color="gray"
										disabled
										customClass="w-16 py-1.5 text-xs"
										v-else
										>
										<p class="text-xs">N/A</p>
									</button-gista>
								</div>
							</td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'

export default {
	name: 'VendorCandidateScoringTable',
    data() {
        return {
            MESSAGE_EMPTY_FIELD
        }
    },
	props: {
		edit: {
			type: Boolean,
			default: true
		}
	},
	computed: {
        selectedCandidate: {
            get() {
                return this.$store.state.procurementApprovalRfq.selectedCandidate
            },
            set(value) {
                this.$store.commit('procurementApprovalRfq/SET_SELECTED_CANDIDATE', value)
            }
        },
        vendorCandidateScoring: {
            get() {
                return this.$store.state.procurementApprovalRfq.vendorCandidateScoring
            },
            set(value) {
                this.$store.commit('procurementApprovalRfq/SET_VENDOR_CANDIDATE_SCORING', value)
            }
        },
        errorVendorCandidate: {
            get() {
                return this.$store.state.procurementApprovalRfq.errorVendorCandidate
            },
            set(value) {
                this.$store.commit('procurementApprovalRfq/SET_ERROR_VENDOR_CANDIDATE', value)
            }
        },
	},
	methods: {
		changeStatus(e, i) {
            const selected = this.vendorCandidateScoring[i]
            if(selected.status === e) return
            selected.status = e
            const candidates = [...this.selectedCandidate]
			if(this.errorVendorCandidate[i]) {
				this.errorVendorCandidate[i].err = false
			}
            if(e === 'Terpilih') this.selectedCandidate = [...candidates, selected]
            else {
                const removeIndex = candidates.map(item => item.vendorSequence).indexOf(selected.vendorSequence);
                removeIndex >= 0 && candidates.splice(removeIndex, 1);
                this.selectedCandidate = candidates
            }
		},
		changeStatusLocal(e, i, localIdx) {
            const selected = this.vendorCandidateScoring[i]
            const local = selected.localPartner[localIdx]
            if(local.status === e) return
            local.status = e
            const candidates = [...this.selectedCandidate]
			if(this.errorVendorCandidate?.[i]?.local?.[localIdx]) {
				this.errorVendorCandidate[i].local[localIdx].err = false
			}
            if(e === 'Terpilih') this.selectedCandidate = [...candidates, local]
            else {
                const removeIndex = candidates.map(item => item.vendorSequence).indexOf(local.vendorSequence);
                removeIndex >= 0 && candidates.splice(removeIndex, 1);
                this.selectedCandidate = candidates
            }
		},
	},
	mounted() {
		if(!this.edit) this.changeStatus('Terpilih', 0)
	},
}
</script>
<style scoped>
.max-h-custom {
	max-height: 40rem;
}
.min-w-vendor {
	min-width: 16rem;
}
.min-w-status {
	min-width: 16rem;
}

</style>
