<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex justify-between my-6">
        <div>
          <span class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"
            @click="clickBackToVendorDetail"></span>
        </div>
        <div>
            <h4 v-if="!showEditBan" class="text-lg font-medium text-gray-darkest">Detail BAN - {{ banDetails.negotiationStatus }}</h4>
            <h4 v-else class="text-lg font-medium text-gray-darkest">Edit BAN - {{ banDetails.negotiationStatus }}</h4>
        </div>
        <div></div>
      </div>
      <div>
        <div class="flex justify-between mb-6" v-if="showEditBan">
          <div class="flex items-center">
            <h5 class="text-base text-gray-darkest">{{ banDetails.vendorName }}</h5>
          </div>
          <div>
            <button-gista
              @click="clickViewBan"
              type="secondary"
              color="success"
              size="small"
              v-if="menuRfqNegotiationBanReport"
              >
              View BAN
              <template #icon-left>
                <span class="mr-4 text-lg icon-eye text-success"></span>
              </template>
            </button-gista>
          </div>
        </div>
        <ValidationObserver ref="formDealBan">
          <div class="flex items-center mb-6">
            <span class="mr-4 icon-list"></span>
            <h5 class="text-lg font-medium text-gray-darkest">Detail BAN</h5>
          </div>
          <div class="grid gap-x-6 gap-y-3.5 mb-3.5"
            :class="{
              'grid-cols-1': quotationDetailContainerWidth < 768,
              'grid-cols-2': quotationDetailContainerWidth >= 768
            }">
            <InputDisabled 
              label="Project ID" 
              :value="banDetails.projectId"
            />
            <InputDisabled 
              label="Request No."
              :value="banDetails.requestNo"
            />
            <InputDisabled 
              label="Vendor Name"
              :value="banDetails.vendorName"
            />
            <InputDisabled 
              label="Project Name"
              :value="banDetails.projectName"
            />
            <InputDisabled 
              label="Request By"
              :value="banDetails.requestByName?.toLowerCase()"
              class="capitalize"
            />
            <InputDisabled 
              label="Created By"
              :value="banDetails.createdByName?.toLowerCase()"
              class="capitalize"
            />
            <InputDisabled 
              label="PIC GSIT"
              :value="banDetails.picGsit?.toLowerCase()"
              class="capitalize"
              v-if="workflowGsit"
            />
            <InputDisabled 
              label="PIC UKKP"
              :value="banDetails.picUkkp?.toLowerCase()"
              class="capitalize"
              v-if="!workflowGsit"
            />
            <InputDisabled 
              label="Date Created"
              :value="banDetails.createdDate ?? '(auto-generate)'"
            />
          </div>
          <div class="mb-6">
            <div class="mb-1">
              <p class="text-sm font-medium text-gray">Project Type</p>
            </div>
            <div class="flex flex-wrap">
              <div class="flex items-center mb-3">
                <span v-if="banDetails.projectType?.includes('HARDWARE')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Hardware</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="banDetails.projectType?.includes('SOFTWARE')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Software</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="banDetails.projectType?.includes('SERVICE')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Service</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="banDetails.projectType?.includes('MAINTENANCE')"  class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Maintenance</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="banDetails.projectType?.includes('LINK_COMMUNICATION')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Link-Communication</p>
              </div>
            </div>
          </div>
          <div class="mb-6">
            <NegotiationDealTable/>
          </div>
          <div class="grid gap-x-6 gap-y-3.5 mb-3.5"
            :class="{
              'grid-cols-1': quotationDetailContainerWidth < 768,
              'grid-cols-2': quotationDetailContainerWidth >= 768
            }">
            <InputDisabled
              label="Negotiator 1"
              :value="banDetails.nameNegotiator1?.toLowerCase()"
              class="capitalize "
            />
            <InputDisabled
              label="Negotiator 2"
              :value="banDetails.nameNegotiator2?.toLowerCase() ?? '-'"
              class="capitalize "
            />
            <AutocompleteNewSolutip
              label="ITP Verificator (S5-S4)"
              placeholder="(Search)"
              :options="approverNegotiation"
              @updateInput="inputSearchItpVerificator($event)"
              @updateAutoComplete="changeItpVerificator($event)"
              @blur="blurItpVerificator"
              @focus="focusItpVerificator"
              v-model="searchItpVerificator"
              textField="name"
              textField2="jobTitle"
              iconLeft="icon-search text-xl text-gray-light"
              :isLoading="isLoadingVerificator"
              :error="error['ITP Verificator']"
              :mandatory="true"
            >
            <template v-if="error['ITP Verificator']" #message>
              <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
            </template>
            </AutocompleteNewSolutip>
            <AutocompleteNewSolutip
              label="Approval ITP (S3-S1)"
              placeholder="(Search)"
              :options="approverNegotiation"
              @updateInput="inputSearchApprovalItp($event)"
              @updateAutoComplete="changeApprovalItp($event)"
              @blur="blurApprovalItp"
              @focus="focusApprovalItp"
              v-model="searchApprovalItp"
              textField="name"
              textField2="jobTitle"
              iconLeft="icon-search text-xl text-gray-light"
              :isLoading="isLoadingVerificator"
              :error="error['Approval ITP']"
              :mandatory="true"
            >
            <template v-if="error['Approval ITP']" #message>
              <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
            </template>
            </AutocompleteNewSolutip>
            <AutocompleteNewSolutip
              v-if="workflowGsit"
              label="Representative GSIT (S3-S2)"
              placeholder="(Search)"
              :options="approverNegotiation"
              @updateInput="inputSearchRepresentatifGsit($event)"
              @updateAutoComplete="changeRepresentatifGsit($event)"
              @blur="blurRepresentatifGsit"
              @focus="focusRepresentatifGsit"
              v-model="searchRepresentatifGsit"
              textField="name"
              textField2="jobTitle"
              iconLeft="icon-search text-xl text-gray-light"
              :isLoading="isLoadingVerificator"
            >
            </AutocompleteNewSolutip>
            <AutocompleteNewSolutip
              v-if="workflowGsit"
              label="Approval GSIT (S1)"
              placeholder="(Search)"
              :options="approverNegotiation"
              @updateInput="inputSearchApprovalGsit($event)"
              @updateAutoComplete="changeApprovalGsit($event)"
              @blur="blurApprovalGsit"
              @focus="focusApprovalGsit"
              v-model="searchApprovalGsit"
              textField="name"
              textField2="jobTitle"
              iconLeft="icon-search text-xl text-gray-light"
              :isLoading="isLoadingVerificator"
              :error="error['Approval GSIT']"
              :mandatory="workflowGsit"
            >
            <template v-if="error['Approval GSIT']" #message>
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
            </template>
            </AutocompleteNewSolutip>
            <AutocompleteNewSolutip
              label="Representative UKKP (S3-S2)"
              placeholder="(Search)"
              :options="approverNegotiation"
              @updateInput="inputSearchRepresentatifUkkp($event)"
              @updateAutoComplete="changeRepresentatifUkkp($event)"
              @blur="blurRepresentatifUkkp"
              @focus="focusRepresentatifUkkp"
              v-model="searchRepresentatifUkkp"
              textField="name"
              textField2="jobTitle"
              iconLeft="icon-search text-xl text-gray-light"
              :isLoading="isLoadingVerificator"
            >
            </AutocompleteNewSolutip>
            <AutocompleteNewSolutip
              label="Approval UKKP (S1)"
              placeholder="(Search)"
              :options="approverNegotiation"
              @updateInput="inputSearchApprovalUkkp($event)"
              @updateAutoComplete="changeApprovalUkkp($event)"
              @blur="blurApprovalUkkp"
              @focus="focusApprovalUkkp"
              v-model="searchApprovalUkkp"
              textField="name"
              textField2="jobTitle"
              iconLeft="icon-search text-xl text-gray-light"
              :isLoading="isLoadingVerificator"
              :error="error['Approval UKKP']"
              :mandatory="!workflowGsit"
            >
            <template v-if="error['Approval UKKP']" #message>
              <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
            </template>
            </AutocompleteNewSolutip>
          </div>
          <div class="grid gap-x-6 gap-y-3.5 pb-3.5"
            :class="{
              'grid-cols-1': quotationDetailContainerWidth < 768,
              'grid-cols-2': quotationDetailContainerWidth >= 768
            }">
            <div class="col-span-2">
              <ValidationProvider name="Term Of Payment"
                rules="required"
                v-slot="{ errors }">
                <div class="flex items-center">
                  <label class="block mb-1 text-sm text-gray" :class="(errors?.[0] || errorMaxLength['Term of Payment']) ? 'text-error' : 'text-gray'">
                    Term of Payment <span class="text-error">*</span>
                  </label>
                </div>
                <div class="">
                  <Tiptap @char="(e) => characterCount(e, 'Term of Payment')" v-model="banDetails.termOfPayment" :error="!!errors[0] || !!errorMaxLength['Term of Payment']">
                    <template #message>
                      <p>{{ errors[0] || errorMaxLength['Term of Payment'] }}</p>
                    </template>
                  </Tiptap>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-span-2">
              <SelectGista
                label="Waiver BG"
                :options="waiverBgOptions"
                :value="waiverBgText"
                @change="changeWaiverBg"
                :error="error['Waiver BG']"
                v-if="waiverBgText"
              >
                <template #selected>
                  {{ waiverBgText || 'Select' }}
                </template>
              </SelectGista>
              <div v-if="error['Waiver BG']">
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </div>
            </div>
            <div class="col-span-2">
              <ValidationProvider name="Negotiation Note"
                rules="required"
                v-slot="{ errors }">
                <div class="flex items-center">
                  <label class="block mb-1 text-sm text-gray" :class="(errors?.[0] || errorMaxLength['Negotiation Note/ BCA Request']) ? 'text-error' : 'text-gray'">
                    Negotiation Note/ BCA Request <span class="text-error">*</span>
                  </label>
                </div>
                <div class="">
                  <Tiptap @char="(e) => characterCount(e, 'Negotiation Note/ BCA Request')" v-model="banDetails.negotiationNote" :error="!!errors?.[0]  || !!errorMaxLength['Negotiation Note/ BCA Request']">
                    <template #message>
                      <p>{{ errors[0]  || errorMaxLength['Negotiation Note/ BCA Request']}}</p>
                    </template>
                  </Tiptap>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-span-2">
              <div class="flex items-center">
                <label class="block mb-1 text-sm text-gray" :class="errorMaxLength['Note Internal BCA']? 'text-error' : 'text-gray'">
                  Note Internal BCA
                </label>
              </div>
              <div class="">
                <Tiptap @char="(e) => characterCount(e, 'Note Internal BCA')" v-model="banDetails.internalNote" :error="!!errorMaxLength['Note Internal BCA']">
                  <template #message>
                    {{ errorMaxLength['Note Internal BCA'] }}
                  </template>
                </Tiptap>
              </div>
            </div>
            <div class="col-span-2" v-for="n,i in fileQuotation" :key="i">
              <div>
                <p class="mb-1 text-sm font-medium text-gray">File Quotation</p>
              </div>
              <div class="flex items-center justify-between p-2 border rounded-lg border-primary h-14">
                <div class="text-base text-gray">{{ n.fileName }}</div>
                <p class="text-xl cursor-pointer icon-eye text-primary hover:text-primary-darkest" @click="openFile(n.fileName, n.documentNo)"></p>
              </div>
            </div>
          </div>
          <div class="mb-6">
            <div class="border border-gray-light rounded-lg p-3.5">
            <div class="flex items-center mb-6">
              <span class="mr-4 icon-list"></span>
              <h5 class="text-lg font-medium text-gray-darkest">Summary Negotiation</h5>
            </div>
            <div class="grid gap-x-6 gap-y-3.5 pb-3.5"
              :class="{
                'grid-cols-1': quotationDetailContainerWidth < 768,
                'grid-cols-2': quotationDetailContainerWidth >= 768
              }">
              <div>
                <SelectGista
                  label="Initial Price"
                  :options="optionInitialPrice"
                  @change="changeInitialPrice"
                  :value="summaryWithText"
                >
                  <template #selected>
                    {{ summaryWithText }}
                  </template>
                </SelectGista>
              </div>
              <div>
                <InputDisabled label="Final Price" :value="banDetails.negotiationStatus" size="medium"/>
              </div>
            </div>
            <NegotiationTableSummary/>
          </div>
          </div>
          <div class="mb-6">
              <div class="flex flex-wrap items-center justify-between w-full gap-3 mt-3 mb-6">
              <div class="flex items-center">
                <span class="mr-4 icon-list"></span>
                <h5 class="text-lg font-medium text-gray-darkest">Negotiation details</h5>
              </div>
              <button class="flex items-center" :class="`${disableMaximizeTable? 'cursor-not-allowed': 'cursor-pointer hover:text-gray-dark active:text-gray-darkest'} ${quotationDetailContainerWidth < 640 ? '' : 'pl-4'}`"
                  @click="clickToggleMaximizeTable"
                  :disabled="disableMaximizeTable"
                  >
                <span class="mr-3 text-lg icon-maximize text-gray"></span>
                <p class="text-sm font-semibold text-gray" >Maximize table</p>
              </button>
            </div>
            <MaximizeTable v-if="isOpenMaximizeTable" :isOpen="isOpenMaximizeTable" @clickToggle="clickToggleMaximizeTable"/>
            <NegotiationTableDetails/>
          </div>
          <div>
            <div class="flex flex-wrap items-center justify-between w-full gap-6" :class="quotationDetailContainerWidth < 640 ? 'flex-col' : 'flex-row'">
              <div :class="quotationDetailContainerWidth < 640? 'w-full' : 'w-max'">
                <DealSaveAsDraft
                :isOpenBanSaveAsDraft = "isOpenBanSaveAsDraft"
                v-if="menuRfqNegotiationOfferDeal"
                @clickToggle="changeIsOpenSaveAsDraft($event)"
                @click="clickSaveAsDraft"
                />
              </div>
              <div class="flex gap-6" :class="quotationDetailContainerWidth < 640 ? 'flex-col w-full' : 'flex-row'">
                <ButtonGista
                 type="secondary" 
                 color="primary"
                 @click="clickPreviewBan"
                 v-if="menuRfqNegotiationBanPreview"
                 >Preview BAN</ButtonGista>
                <SubmitBAN 
                :isOpenSubmitBAN="isOpenSubmitBAN"
                @clickToggleSubmit="changeIsOpenSubmitBan($event)"
                @click="clickSubmitBan" 
                v-if="menuRfqNegotiationOfferDeal"
                ></SubmitBAN>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <BanLoading v-else/>
    <ModalError v-if="errObj" :errObj="errObj" @clearErrObj="() => errObj = {}" @click="errClick" />
  </div>
</template>

<script>
  import MaximizeTable from "@/core/components/rfq/negotiation/MaximizeTable.vue";
  import Tiptap from '@/core/components/custom/Tiptap.vue'
  import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
  import NegotiationDealTable from "@/core/components/rfq/negotiation/NegotiationDealTable.vue";
  import NegotiationTableSummary from "@/core/components/rfq/negotiation/NegotiationTableSummary.vue";
  import SubmitBAN from '@/core/components/modal/negotiation/SubmitBan.vue'
  import NegotiationTableDetails from "@/core/components/rfq/negotiation/NegotiationTableDetails.vue";
  import DealSaveAsDraft from "@/core/components/modal/negotiation/DealSaveAsDraft.vue";
  import InputDisabled from '@/core/components/custom/InputDisabled.vue'
  import BanLoading from '@/core/components/rfq/loading/BanLoading.vue'
  import { MESSAGE_EMPTY_FIELD, MESSAGE_POPUP_VALIDATION_SUBMIT } from '@/core/constant/errorMessage.js'
  import { MESSAGE_SUCCESS_SUBMIT_BAN } from '@/core/constant/successMessage'
  import ModalError from '@/core/components/modal/negotiation/BanDealError.vue';

  export default {
    name: 'DealBan',
    data() {
      return {
        isOpenBanSaveAsDraft: false,
        isOpenModalSendEmail: false,
        isOpenSubmitBAN: false,
        isOpenMaximizeTable: false,
        time: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
        searchItpVerificator: '',
        searchApprovalItp: '',
        searchRepresentatifGsit: '',
        searchApprovalGsit: '',
        searchRepresentatifUkkp: '',
        searchApprovalUkkp: '',
        summaryWithText: '',
        loadingFile: false,
        isLoading: false,
        showSendEmailModal: false,
        attachments: [''],
        waiverBgOptions: [
        { value: 'TANPA_WAIVER', text: 'No Waiver'}, 
        { value: 'WAIVER_PERFOMANCE_BOND', text: 'Perfomance Bond' }, 
        { value: 'WAIVER_ADVANCE_PAYMENT_BOND', text: 'Advance Payment Bond' }, 
        { value: 'WAIVER_ADVANCE_PAYMENT_BOND_AND_PERFORMANCE_BOND', text: 'Advance Payment Bond and Performance Bond'}],
        MESSAGE_EMPTY_FIELD,
        error: {
          "ITP Verificator": false,
          "Approval ITP": false,
          "Approval GSIT": false,
          "Approval UKKP": false,
          "Waiver BG": false,
        },
        isLoadingVerificator: false,
        initialPriceSelect: '',
        errorMaxLength: {
          'Negotiation Note': '',
          'Term of Payment': '',
          'Note Internal BCA': ''
        },
        errObj: {}
      }
    },
    computed: {
      negotiationDetail(){
        return this.$store.state.negotiationRfq.negotiationDetail
      },
      disableMaximizeTable() {
        return this.negotiationDetail?.offers?.length === 0
      },
      banDetails: {
        get() {
          return this.$store.state.negotiationRfq.banDetails
        },
        set(value) {
          this.$store.commit('negotiationRfq/SET_NEGOTIATION_OFFER_BAN', value)
        }
        
      },
      approverNegotiation() {
        return this.$store.state.negotiationRfq.approverNegotiation.filter(e => !this.filterForApproverNegotiation.includes(e.userName))
      },
      filterForApproverNegotiation() {
        let people = []
        if (this.searchItpVerificator) {
          people.push(this.banDetails.itpVerificatorId)
        }
        if (this.searchApprovalItp) {
          people.push(this.banDetails.directApproverItpId)
        }
        if (this.searchRepresentatifGsit) {
          people.push(this.banDetails.representGsitId)
        }
        if (this.searchApprovalGsit) {
          people.push(this.banDetails.directApproverGsitId)
        }
        if (this.searchRepresentatifUkkp) {
          people.push(this.banDetails.representUkkpId)
        }
        if (this.searchApprovalUkkp) {
          people.push(this.banDetails.directApproverUkkpId)
        }
        return people
      },
      quotationDetailContainerWidth() {
        return this.$store.state.width.quotationDetailContainerWidth
      },
      optionInitialPrice(){
        return this.banDetails.negotiationHistories?.filter((e,i) => i !== this.banDetails.negotiationHistories.length - 1).map(e =>({text:e.negotiationStatus, value:e.rfqNegotiationId}))
      },
      waiverBgText: {
        get() {
          return this.waiverBgOptions.find(e => e.value === this.banDetails.isWaiverBg)?.text
        },
        set(val) {
          this.banDetails.isWaiverBg = val
        }
      },
      showEditBan() {
        return this.$store.state.negotiationRfq.showEditBan
      },
      workflowGsit() {
        return this.banDetails.isUnitGsit
      },
      menuRfqNegotiationBanReport() {
        return this.$store.getters['dashboard/menuRfqNegotiationBanReport']?.canRead
      },
      menuRfqNegotiationBanPreview() {
        return this.$store.getters['dashboard/menuRfqNegotiationBanPreview']?.canCreate
      },
      fileQuotation() {
        return this.banDetails?.attachments?.filter(i => i.fileRef === 'fileQuotation')
      },
      modal: {
        get() {
          return this.$store.state.modal.modal
        },
        set(value) {
          this.$store.commit('modal/SET_MODAL', value)
        }
      },
      projectLogRfq() {
        return this.$store.state.projectLog.projectLogRfq
      },
      menuRfqNegotiationOfferDeal() {
        return this.$store.getters['dashboard/menuRfqNegotiationOfferDeal']?.canCreate
      },
    },
    methods: {
      checkErrorLength(){
        return Object.values(this.errorMaxLength).every(e => !e)
      },
      messageErrorLength(){
        const filterError = Object.keys(this.errorMaxLength).filter(e => this.errorMaxLength[e])
        return filterError.join(', ')
      },
      characterCount(e, name) {
        if(e > 4500) this.errorMaxLength[name] = `Kolom ${name} Maksimal 4500 Karakter`
        else this.errorMaxLength[name] = ''
      },
      changeIsOpenSaveAsDraft(val){
        this.isOpenBanSaveAsDraft = val
      },
      changeIsOpenSubmitBan(val) {
        this.isOpenSubmitBAN = val
      },
      clickBackToVendorDetail() {
        this.$store.commit('negotiationRfq/SET_SHOW_DEAL_BAN', false)
      },
      async inputSearchItpVerificator(e) {
        this.isLoadingVerificator = true
        this.searchItpVerificator = e
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: e, project: 'RFQ', param: 'ITP_VERIFICATOR' })
        this.isLoadingVerificator = false
      },
      changeItpVerificator(e) {
        this.banDetails.itpVerificatorId = e.userName
        this.banDetails.itpVerificatorName = e.name
        this.searchItpVerificator = e.name
        this.error['ITP Verificator'] = false
      },
      blurItpVerificator() {
        if(this.searchItpVerificator !== '') this.searchItpVerificator = this.banDetails.itpVerificatorName
        else {
          this.banDetails.itpVerificatorId = ''
          this.banDetails.itpVerificatorName = ''
        }
      },
      async focusItpVerificator() {
        this.isLoadingVerificator = true
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: this.searchItpVerificator, project: 'RFQ', param: 'ITP_VERIFICATOR' })
        this.isLoadingVerificator = false
      },

      async inputSearchApprovalItp(e) {
        this.isLoadingVerificator = true
        this.searchApprovalItp = e
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: e, project: 'RFQ', param: 'SPV_ITP_APPROVAL' })
        this.isLoadingVerificator = false
      },
      changeApprovalItp(e) {
        this.banDetails.directApproverItpId = e.userName
        this.banDetails.directApproverItpName = e.name
        this.searchApprovalItp = e.name
        this.error['Approval ITP'] = false
      },
      blurApprovalItp() {
        if(this.searchApprovalItp !== '') this.searchApprovalItp = this.banDetails.directApproverItpName
        else {
          this.banDetails.directApproverItpId = ''
          this.banDetails.directApproverItpName = ''
        }
      },
      async focusApprovalItp() {
        this.isLoadingVerificator = true
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: this.searchApprovalItp, project: 'RFQ', param: 'SPV_ITP_APPROVAL' })
        this.isLoadingVerificator = false
      },

      async inputSearchRepresentatifGsit(e) {
        this.isLoadingVerificator = true
        this.searchRepresentatifGsit = e
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: e, project: 'RFQ', param: 'REPRESENTATIVE_GSIT' })
        this.isLoadingVerificator = false
      },
      changeRepresentatifGsit(e) {
        this.banDetails.representGsitId = e.userName
        this.banDetails.representGsitName = e.name
        this.searchRepresentatifGsit = e.name
      },
      blurRepresentatifGsit() {
        if(this.searchRepresentatifGsit !== '') this.searchRepresentatifGsit = this.banDetails.representGsitName
        else {
          this.banDetails.representGsitName = ''
          this.banDetails.representGsitId = ''
        }
      },
      async focusRepresentatifGsit() {
        this.isLoadingVerificator = true
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: this.searchRepresentatifGsit, project: 'RFQ', param: 'REPRESENTATIVE_GSIT' })
        this.isLoadingVerificator = false
      },
      async inputSearchApprovalGsit(e) {
        this.isLoadingVerificator = true
        this.searchApprovalGsit = e
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: e, project: 'RFQ', param: 'SPV_GSIT_APPROVAL' })
        this.isLoadingVerificator = false
      },
      changeApprovalGsit(e) {
        this.banDetails.directApproverGsitId = e.userName
        this.banDetails.directApproverGsitName = e.name
        this.searchApprovalGsit = e.name
        this.error['Approval GSIT'] = false
      },
      blurApprovalGsit() {
        if(this.searchApprovalGsit !== '') this.searchApprovalGsit = this.banDetails.directApproverGsitName
        else {
          this.banDetails.directApproverGsitName = ''
          this.banDetails.directApproverGsitId = ''
        }
      },
      async focusApprovalGsit() {
        this.isLoadingVerificator = true
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: this.searchApprovalGsit, project: 'RFQ', param: 'SPV_GSIT_APPROVAL' })
        this.isLoadingVerificator = false
      },

      async inputSearchRepresentatifUkkp(e) {
        this.isLoadingVerificator = true
        this.searchRepresentatifUkkp = e
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: e, project: 'RFQ', param: 'REPRESENTATIVE_UKKP' })
        this.isLoadingVerificator = false
      },
      changeRepresentatifUkkp(e) {
        this.banDetails.representUkkpId = e.userName
        this.banDetails.representUkkpName = e.name
        this.searchRepresentatifUkkp = e.name
      },
      blurRepresentatifUkkp() {
        if(this.searchRepresentatifUkkp !== '') this.searchRepresentatifUkkp = this.banDetails.representUkkpName
        else {
          this.banDetails.representUkkpName = ''
          this.banDetails.representUkkpId = ''
        }
      },
      async focusRepresentatifUkkp() {
        this.isLoadingVerificator = true
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: this.searchRepresentatifUkkp, project: 'RFQ', param: 'REPRESENTATIVE_UKKP' })
        this.isLoadingVerificator = false
      },

      async inputSearchApprovalUkkp(e) {
        this.isLoadingVerificator = true
        this.searchApprovalUkkp = e
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: e, project: 'RFQ', param: 'SPV_UKKP_APPROVAL' })
        this.isLoadingVerificator = false
      },
      changeApprovalUkkp(e) {
        this.banDetails.directApproverUkkpId = e.userName
        this.banDetails.directApproverUkkpName = e.name
        this.searchApprovalUkkp = e.name
        this.error['Approval UKKP'] = false
      },
      blurApprovalUkkp() {
        if(this.searchApprovalUkkp !== '') this.searchApprovalUkkp = this.banDetails.directApproverUkkpName
        else {
          this.banDetails.directApproverUkkpName = ''
          this.banDetails.directApproverUkkpId = ''
        }
      },
      async focusApprovalUkkp() {
        this.isLoadingVerificator = true
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: this.searchApprovalUkkp, project: 'RFQ', param: 'SPV_UKKP_APPROVAL' })
        this.isLoadingVerificator = false
      },
      changeInitialPrice(e){
        const negotiationId = e.value
        this.banDetails.summaryRfqNegotiationId = e.value
        this.summaryWithText = e.text
        this.$store.dispatch('negotiationRfq/getSummaryWith', {negotiationId,rfqId:this.$route.params.quotationId})
      },
      changeWaiverBg(e) {
        this.waiverBgText = e.value
        this.error['Waiver BG'] = false
      },
      payloadDeal() {
        return {
            rfqNegotiationId: this.banDetails.rfqNegotiationId,
            summaryRfqNegotiationId: this.banDetails.summaryRfqNegotiationId,
            projectId: this.banDetails.projectId,
            requestNo: this.banDetails.requestNo,
            vendorName: this.banDetails.vendorName,
            projectName: this.banDetails.projectName,
            budgetSpent: this.banDetails.budgetSpent,
            requestById: this.banDetails.requestById,
            requestByName: this.banDetails.requestByName,
            offerNo: this.banDetails.offerNo,
            projectType: this.banDetails.projectType,
            meeting: this.banDetails.meeting,
            meetingDate: this.banDetails.meetingDate,
            timeStart: this.banDetails.timeStart,
            timeEnd: this.banDetails.timeEnd,
            location: this.banDetails.location,
            viaOnline: this.banDetails.viaOnline,
            userIdNegotiator1: this.banDetails.userIdNegotiator1,
            nameNegotiator1: this.banDetails.nameNegotiator1,
            userIdNegotiator2: this.banDetails.userIdNegotiator2,
            nameNegotiator2: this.banDetails.nameNegotiator2,

            negotiationParticipant: this.banDetails.negotiationParticipant?.map(n=>( n.userDomain)),

            termOfPayment: this.banDetails.termOfPayment,
            isWaiverBg: this.banDetails.isWaiverBg,
            negotiationNote: this.banDetails.negotiationNote,
            proofOfMeetingDocumentNo: this.banDetails.proofOfMeetingDocumentNo,
            proofOfMeetingFileName: this.banDetails.proofOfMeetingFileName,
            proofOfMeetingFilePath: this.banDetails.proofOfMeetingFilePath,

            itpVerificatorId: this.banDetails.itpVerificatorId,
            itpVerificatorName: this.banDetails.itpVerificatorName,

            representGsitId: this.banDetails.representGsitId,
            representGsitName: this.banDetails.representGsitName,
            representUkkpId: this.banDetails.representUkkpId,
            representUkkpName: this.banDetails.representUkkpName,

            directApproverItpId: this.banDetails.directApproverItpId,
            directApproverItpName: this.banDetails.directApproverItpName,

            directApproverGsitId: this.banDetails.directApproverGsitId,
            directApproverGsitName:this.banDetails.directApproverGsitName,
            directApproverUkkpId: this.banDetails.directApproverUkkpId,
            directApproverUkkpName:this.banDetails.directApproverUkkpName,

            attachments: this.banDetails.attachments.map(n=>({...n, attachmentId: n.id})),
            internalNote: this.banDetails.internalNote,
          }
      },
      async clickSaveAsDraft() {
        try {
          this.modal.modalLoading = true
          const checkErrorLength = this.checkErrorLength()
          const MESSAGE_ERROR_VALIDATE = this.messageErrorLength()
          if (!checkErrorLength) {
            this.modal.modalLoading = false
            this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + MESSAGE_ERROR_VALIDATE }
            this.changeIsOpenSaveAsDraft(false)
            return
          }
          const payload = this.payloadDeal()
          const response = await this.$store.dispatch('negotiationRfq/postBanSaveAsDraft', payload)
          if (response?.status < 300 && response?.status >= 200) {
            this.modal.modalLoading = false
            this.changeIsOpenSaveAsDraft(false)
            setTimeout(() =>{
              this.$store.commit('negotiationRfq/SET_SHOW_DEAL_BAN', false)
            },300)
          } else {
            this.$store.dispatch('modal/throwError', { response })
          }
        } catch (error) {
          console.log(error);
          this.modal.modalLoading = false
          this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
        }
      },
      checkErrorValidate() {
        if (!this.banDetails.itpVerificatorId) {
          this.error['ITP Verificator'] = true
        }
        if (!this.banDetails.directApproverItpId) {
          this.error['Approval ITP'] = true
        }
        if (!this.searchApprovalGsit && (this.workflowGsit)) {
          this.error['Approval GSIT'] = true
        }
        if (!this.searchApprovalUkkp && (!this.workflowGsit)) {
          this.error['Approval UKKP'] = true
        }
        if (!this.banDetails.isWaiverBg) {
          this.error['Waiver BG'] = true
        }
        return Object.values(this.error).every(e => !e)
      },
      messageErrorValidate() {
        const errorVeeValidate = this.$refs.formDealBan.errors
        const filterError = Object.keys(errorVeeValidate).filter(e => errorVeeValidate[e].length > 0)
        const filterErrorCustom = Object.keys(this.error).filter(e => this.error[e] === true)
        return [ ...filterErrorCustom, ...filterError ].join(', ')
      },
      async clickSubmitBan(){
        try {
          this.modal.modalLoading = true
          const successValidate = await this.$refs.formDealBan.validate()
          const checkErrorValidate = this.checkErrorValidate()
          const MESSAGE_ERROR_VALIDATE = this.messageErrorValidate()
          const checkErrorLength = this.checkErrorLength()
          const MESSAGE_ERROR_LENGTH = this.messageErrorLength()
          if (!successValidate || !checkErrorValidate || !checkErrorLength) {
            this.modal.modalLoading = false
            const message = MESSAGE_ERROR_VALIDATE && MESSAGE_ERROR_LENGTH ? `, ${MESSAGE_ERROR_LENGTH}` : MESSAGE_ERROR_LENGTH
            this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + MESSAGE_ERROR_VALIDATE + message }
            this.changeIsOpenSubmitBan(false) 
            return
          }
          const payload = this.payloadDeal()
          const response = await this.$store.dispatch('negotiationRfq/putNegotiationOfferDeal', payload)
          if (response?.status < 300 && response?.status >= 200) {
            this.modal.modalLoading = false
            this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_SUBMIT_BAN }
            this.isOpenModalSendEmail = false
            this.changeIsOpenSubmitBan(false) 
            this.$store.commit('projectLog/SET_RFQ_LOG_ID', this.projectLogRfq?.rfqLogs?.at(-1)?.rfqLogId)
            this.$emit("refetch")
            setTimeout(() => {
              this.$store.commit('negotiationRfq/SET_SHOW_DEAL_BAN', false)
            },300)
          } else {
            this.$store.dispatch('modal/throwError', { response })
          }
        } catch (error) {
          this.changeIsOpenSubmitBan(false) 
          console.log(error);
          this.modal.modalLoading = false
          if(error.message.includes('draft')) {
            this.errObj = { value: true, traceid: error.traceid, title: error.title, message: error.message }
          } else this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
        }
      },
      async errClick() {
        await this.clickSaveAsDraft()
      },
      async clickPreviewBan(){
        try {
          this.modal.modalLoading = true
          const checkErrorLength = this.checkErrorLength()
          const MESSAGE_ERROR_VALIDATE = this.messageErrorLength()
          if (!checkErrorLength) {
            this.modal.modalLoading = false
            this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + MESSAGE_ERROR_VALIDATE }
            return
          }
          const payload = this.payloadDeal()
          const response = await this.$store.dispatch('negotiationRfq/postBanPreview', payload)
          if (response?.status < 300 && response?.status >= 200) {
            this.modal.modalLoading = false
          } else {
            this.$store.dispatch('modal/throwError', { response })
          }
        } catch (error) {
          console.log(error);
          this.modal.modalLoading = false
          this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
        }
      },
      async clickViewBan() {
        this.modal.modalLoading = true
        await this.$store.dispatch('negotiationRfq/getBanReport', { negotiationId: this.banDetails.rfqNegotiationId })
        this.modal.modalLoading = false
      },
      clickToggleMaximizeTable() {
        this.isOpenMaximizeTable = !this.isOpenMaximizeTable
      },
      setSearch() {
        this.searchRepresentatifGsit = this.banDetails.representGsitName || ''
        this.searchApprovalGsit = this.banDetails.directApproverGsitName || ''
        this.searchRepresentatifUkkp = this.banDetails.representUkkpName || ''
        this.searchApprovalUkkp = this.banDetails.directApproverUkkpName || ''
        this.searchItpVerificator = this.banDetails.itpVerificatorName || ''
        this.searchApprovalItp = this.banDetails.directApproverItpName || ''
      },
      openFile(filename, documentNo) {
        this.$store.dispatch('file/viewAttachment', { filename, documentNo })
      },
  
    },
    async  mounted() {
      this.isLoading = true
      const r1 = this.$store.dispatch('negotiationRfq/getNegotiationOfferBan', {negotiationId:this.negotiationDetail.offers[this.negotiationDetail.offers.length -1].negotiationId})
      const r2 = this.$store.dispatch('negotiationRfq/getNegotiationList',{rfqId:this.$route.params.quotationId})
      const [res1, res2] = await Promise.all([r1, r2])
      let res3 = null
      let res4 = null
      if (!this.banDetails.summaryRfqNegotiationId) {
        res3 = await this.$store.dispatch('negotiationRfq/getSummaryWith', { negotiationId: this.negotiationDetail.offers[0].negotiationId,rfqId:this.$route.params.quotationId})
        this.summaryWithText = this.optionInitialPrice.length > 0? this.optionInitialPrice[0].text : ''
        this.banDetails.summaryRfqNegotiationId = this.optionInitialPrice.length > 0? this.optionInitialPrice[0].value : ''
      } else {
        res4 = await this.$store.dispatch('negotiationRfq/getSummaryWith', { negotiationId: this.banDetails.summaryRfqNegotiationId,rfqId:this.$route.params.quotationId})
        this.summaryWithText = this.optionInitialPrice.length > 0? this.optionInitialPrice[this.optionInitialPrice?.map(e => e.value).indexOf(this.banDetails.summaryRfqNegotiationId)].text : ''
      }
      console.log([res1?.status, res2?.status, res3?.status, res4?.status]);
      if ([res1?.status, res2?.status].every(e => e === 200) && [res3?.status, res4?.status].includes(200)) {
        this.isLoading = false
        if (!this.banDetails.isWaiverBg) {
          this.changeWaiverBg(this.waiverBgOptions[0])
        }
        this.setSearch()
      }

    },
    destroyed() {
      this.$store.commit('negotiationRfq/SET_SHOW_DEAL_BAN', false)
      if(this.showEditBan) this.$store.commit('negotiationRfq/SET_SHOW_EDIT_BAN', false)
    },
    components: {
      MaximizeTable,
      Tiptap,
      AutocompleteNewSolutip,
      NegotiationDealTable,
      NegotiationTableSummary,
      SubmitBAN,
      NegotiationTableDetails,
      DealSaveAsDraft,
      InputDisabled,
      BanLoading,
      ModalError
    }
  }
</script>