<template>
  <div class="w-full">
    <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse">
      <div class="flex items-center">
        <span class="mr-4 icon-list"></span>
        <h5 class="text-lg font-medium text-gray-darkest">INFORMATION &nbsp;</h5>
        <span class="text-error" v-if="!readOnly">*</span>
      </div>
      <div>
        <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse.information ? 'rotate-180' : 'rotate-0'"></p>
      </div>
    </div>
    <div v-show="collapse.information">
      <div class="grid gap-x-6 gap-y-3.5 mb-3.5 "
          :class="{
            'grid-cols-1': projectDetailContainerWidth < 768,
            'grid-cols-2': projectDetailContainerWidth >= 768
          }">
        <InputDisabled 
          label="Approval No." 
          value="123"
        />
        <InputDisabled 
          label="Work Unit" 
          value="GSIT"
        />
        <InputDisabled 
          label="Project ID" 
          value="PRJ22001"
        />
        <div>
          <label
            class="block pr-4 mb-1 text-sm truncate text-gray md:text-left"
          >
          Division
          </label>
          <div class="flex w-full">
            <div class="w-full">
              <AutocompleteNewSolutip
                v-if="!readOnly"
                placeholder="Search"
                :options="divisions"
                @updateInput="inputSearch($event, 'searchNegotiator1')"
                @updateAutoComplete="changeRecommendation($event)"
                @focus="focusRecommendation('searchNegotiator1')"
                v-model="approval.division"
                textField="fullName"
              >
              </AutocompleteNewSolutip>
              <InputDisabled
                v-if="readOnly"
                label="" 
                value="GSIT"
              />
            </div>
          </div>
        </div>
  
      </div>
      <div class="col-span-2 mb-3.5">
        <ValidationProvider name="Introduction / Background & Needs"
          rules="required"
          v-slot="{ errors }">
          <div class="flex items-center">
            <label class="block mb-1 text-sm" :class="errors?.[0]  || errorMaxLength['Introduction / Background & Needs']? 'text-error' : 'text-gray'">
              Introduction / Background & Needs <span class="text-error">*</span>
            </label>
          </div>
          <div class="">
            <Tiptap v-if="!readOnly" @char="(e) => characterCount(e, 'Introduction / Background & Needs')" v-model="approval.introduction" :error="!!errors[0] || !!errorMaxLength['Introduction / Background & Needs']">
              <template #message>
                <p>{{ errors[0] || errorMaxLength['Introduction / Background & Needs'] }}</p>
              </template>
            </Tiptap>
            <TiptapView v-else-if="readOnly && approval.introduction" v-model="approval.introduction" />
            <InputDisabled value="-" v-else/>
          </div>
        </ValidationProvider>
      </div>
      <div class="col-span-2 mb-3.5">
        <ValidationProvider name="Project Goals"
          rules="required"
          v-slot="{ errors }">
          <div class="flex items-center">
            <label class="block mb-1 text-sm" :class="errors?.[0]  || errorMaxLength['Project Goals']? 'text-error' : 'text-gray'">
              Project Goals <span class="text-error">*</span>
            </label>
          </div>
          <div class="">
            <Tiptap v-if="!readOnly" @char="(e) => characterCount(e, 'Project Goals')" v-model="approval.projectGoals" :error="!!errors[0] || !!errorMaxLength['Project Goals']">
              <template #message>
                <p>{{ errors[0] || errorMaxLength['Project Goals'] }}</p>
              </template>
            </Tiptap>
            <TiptapView v-else-if="readOnly && approval.projectGoals" v-model="approval.projectGoals" />
            <InputDisabled value="-" v-else/>
          </div>
        </ValidationProvider>
      </div>
      <div class="col-span-2 mb-3.5">
        <div class="flex items-center">
          <label class="block mb-1 text-sm text-gray" :class="errorMaxLength['Note Internal BCA']? 'text-error' : 'text-gray'">
            Note Internal BCA
          </label>
        </div>
        <div class="">
          <Tiptap v-if="!readOnly" @char="(e) => characterCount(e, 'Note Internal BCA')" v-model="approval.internalNote" :error="!!errorMaxLength['Note Internal BCA']">
            <template #message>
              <p>{{ errorMaxLength['Note Internal BCA'] }}</p>
            </template>
          </Tiptap>
          <TiptapView v-else-if="readOnly && approval.internalNote" v-model="approval.internalNote" />
          <InputDisabled value="-" v-else/>
        </div>
      </div>
      <div class="col-span-2 mb-3.5" v-for="n,i in approval?.attachments" :key="i">
        <div>
          <p class="mb-1 text-sm font-medium text-gray">Attachment #{{i+1}}</p>
        </div>
        <div class="flex items-center justify-between p-2 border rounded-lg h-14" :class="editRequester ? 'bg-gray-lightest border-gray-lightest' : 'border-primary'">
          <div class="text-base font-medium text-gray">{{ n.fileName }}</div>
          <span v-if="!editRequester" class="cursor-pointer" :class="readOnly ? 'icon-eye text-primary' : 'icon-trash-2 text-error'" @click="clickRemoveFileAttachment(i)"></span>
        </div>
      </div>
      <div class="col-span-2 mb-3.5" v-if="!readOnly">
        <ValidationProvider
          ref="lampiranApproval"
          >
          <div class="flex flex-wrap items-center">
            <div class="flex" v-if="maxAttachment">
              <span class="mr-2 icon-file-plus text-secondary"></span>
              <input type="file"
                id="upload-file-attachment"
                ref="fileAttachment"
                hidden
                @change="addAttachment($event, 'lampiranApproval')" />
              <label for="upload-file-attachment"
                class="mr-4 text-base font-medium underline cursor-pointer text-secondary">
                Add Attachment
              </label>
            </div>
            <p class="mr-3.5" :class="{ 'text-error': errorFileType.attachment, 'text-gray': !errorFileType.attachment }">Max Attachment: 10 </p>
            <p :class="{ 'text-error': errorFileType.attachment, 'text-gray': !errorFileType.attachment }">Supported file: .pdf, max file size: 10 MB</p>
          </div>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import Tiptap from '@/core/components/custom/Tiptap.vue'
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import TiptapView from '@/core/components/custom/TiptapView.vue'

export default {
  name: "Information",
  props: ['collapse', 'readOnly', 'editRequester'],
  data(){
    return {
      approval: {
        attachments: [],
        division: '',
        type: ''
      },
      errorFileType: false,
      divisions: [],
    }
  },
  computed:{
    errorMaxLength: {
      get() {
        return this.$store.state.procurementApprovalRfq.errorMaxLength
      },
      set(val) {
        this.$store.commit('procurementApprovalRfq/SET_ERROR_MAX_LENGTH', val)
      }
    },
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    maxAttachment() {
      return this.approval.attachments.length < 10
    },
  },
  methods: {
    changeCollapse() {
      this.$emit('changeCollapse')
    },
    async clickRemoveFileAttachment(index){
      try {
        this.approval.attachments = this.approval.attachments.filter((e,i) => i !== index)
      } catch (error) {
        console.log(error)
      }
    },
    async addAttachment(e, fileRef) {
      const file = e.target?.files[0]
      console.log(e);
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
      if(checkFileValidation){
        this.errorFileType = true
      } else {
        try {
          const fileAfterUpload = {fileName: file.name}
          this.approval.attachments = [ ...this.approval.attachments, fileAfterUpload ]
          this.errorFileType = false
        } catch (error) {
          console.log(error)
        }
        
      }
      if (this.$refs[fileRef]) {
        console.log('masuk');
        this.$refs[fileRef].value = ''
      }
    },
    focusRecommendation() {
      this.divisions = [
        { fullName: 'Testing' },
        { fullName: 'Testing 1' },
        { fullName: 'Testing 2' },
        { fullName: 'Testing 3' },
      ]
    },
    changeRecommendation(e, type) {
      if(type) this.approval.type = e.fullName
      else this.approval.division = e.fullName
    },
    inputSearch(e) {
      console.log('search', e)
    },
    characterCount(e, name) {
      const MAX_CHARACTER = 4500
      if(e > MAX_CHARACTER) this.errorMaxLength[name] = `Kolom ${name} Maksimal 4500 Karakter`
      else this.errorMaxLength[name] = ''
    },
  },
  mounted(){
    if(this.readOnly) {
      this.approval= {
        attachments: [{fileName: 'File.pdf'}],
        introduction: 'Ini contoh field text area yang sudah terisi value.',
        projectGoals: 'Ini contoh field text area yang sudah terisi value.',
        internalNote: 'Ini contoh field text area yang sudah terisi value.'
      }
    }
  },
  components:{
    InputDisabled,
    Tiptap,
    AutocompleteNewSolutip,
    TiptapView
  }
}
</script>