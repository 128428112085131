<template>
  <div>
    <div v-for="n, i in fields" :key="i" class="w-full pt-6 mt-6 border-t-2 border-gray-lightest">
      <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse(n.name.toLocaleLowerCase())">
        <div class="flex items-center">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">{{n.name.toLocaleUpperCase()}} &nbsp;</h5>
          <span v-if="!n.optional && (!readOnly || editRequester)" class="text-error">*</span>
        </div>
        <div>
          <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse[n.name.toLocaleLowerCase()] ? 'rotate-180' : 'rotate-0'"></p>
          
        </div>
      </div>
      <div v-show="collapse[n.name.toLocaleLowerCase()]">
        <div class="mb-3.5" v-for="value, j in procurementApproval[n.field]" :key="j">
          <label
            class="block pr-4 mb-1 text-sm truncate md:text-left"
            :class="errorRecommendation[n.name] && j === 0 ? 'text-error' : 'text-gray'"
          >
          {{ `${n.name} ${j + 1}` }} <span v-if="(!n.optional && j === 0 && (!readOnly || editRequester))" class="text-error">*</span>
          </label>
          <div class="flex w-full">
            <div class="w-full">
              <AutocompleteNewSolutip
                v-if="(!readOnly || editRequester)"
                placeholder="Search"
                :options="recommendation"
                @updateInput="inputSearch($event, 'searchNegotiator1')"
                @updateAutoComplete="changeRecommendation($event, n, j)"
                @focus="focusRecommendation('searchNegotiator1')"
                v-model="value.name"
                textField="fullName"
                textField2="jobTitle"
                iconLeft="icon-search text-sm text-gray-light"
                :error="checkError(j, n)"
              >
              </AutocompleteNewSolutip>
              <InputDisabled
                v-if="readOnly && !editRequester"
                label="" 
                value="name"
              />
            </div>
            <div v-if="(!readOnly || editRequester) && (procurementApproval[n.field].length > 1)" class="flex items-center">
              <p @click="handleDelete(n.field, j)" class="ml-3 text-xl cursor-pointer icon-x-circle text-error"></p>
            </div>
          </div>
          <div v-if="checkError(j, n)">
            <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
          </div>
        </div>
        <div class="w-max" v-if="(!readOnly || editRequester)">
          <div @click="handleAdd(n.field)" class="flex items-center cursor-pointer">
            <span class="mr-2 icon-plus-square text-secondary"></span>
            <p 
              class="mr-4 text-base font-medium underline text-secondary">
              Add
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import { MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'

export default {
  name: "Recommendation",
  props: ['collapse', 'readOnly'],
  data() {
    return {
      recommendation: [],
      fields: [
        {name: 'Checker', optional: true, field: 'selectedChecker'},
        {name: 'Recommendation', field: 'selectedRecommendation'},
        {name: 'Approver', field: 'selectedApprover'},
      ],
      MESSAGE_EMPTY_FIELD
    }
  },
  computed: {
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    editRequester: {
      get() {
        return this.$store.state.procurementApprovalRfq.editRequester
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_EDIT_REQUESTER', value)
      }
    },
    procurementApproval() {
      return this.$store.state.procurementApprovalRfq
    },
    errorRecommendation: {
      get() {
        return this.$store.state.procurementApprovalRfq.errorRecommendation
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_ERROR_RECOMMENDATION', value)
      }
    },
    selectedChecker: {
      get() {
        return this.$store.state.procurementApprovalRfq.selectedChecker
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_SELECTED_CHECKER', value)
      }
    },
    selectedRecommendation: {
      get() {
        return this.$store.state.procurementApprovalRfq.selectedRecommendation
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_SELECTED_RECOMMENDATION', value)
      }
    },
    selectedApprover: {
      get() {
        return this.$store.state.procurementApprovalRfq.selectedApprover
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_SELECTED_APPROVER', value)
      }
    },
  },
  methods: {
    checkError(j, n) {
      return j === 0 && (!this.readOnly || this.editRequester) && this.errorRecommendation[n.name]
    },
    selectedLooping(name) {
      return this[name]
    },
    handleDelete(field, j){
      this[field].splice(j, 1)
    },
    handleAdd(field) {
      this[field].push({name: ''})
    },
    focusRecommendation() {
      this.recommendation = [
        { fullName: 'Testing' },
        { fullName: 'Testing 1' },
        { fullName: 'Testing 2' },
        { fullName: 'Testing 3' },
      ]
    },
    changeRecommendation(e, item, j) {
      const {field, name} = item
      this.errorRecommendation[name] = false
      this[field] = this[field].map((l, idx) => idx === j ? ({name: e.fullName}) : l  )
    },
    inputSearch(e) {
      console.log('search', e)
    },
    changeCollapse(key) {
      this.$emit('changeCollapse', key)
    }
  },
  components:{
    AutocompleteNewSolutip,
    InputDisabled
  }
}
</script>