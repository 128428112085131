<template>
	<div class="">
		<div class="w-full " v-if="!isLoading">

			<QuotationInformation/>
			<ItemList/>

			<!-- Send rfq OPR ITP only -->
			<div class="w-full mt-6 mb-6" v-if="menuRfqDetailsSendRfq?.canUpdate && statusApproved">
				<div class="flex items-center mb-6">
					<span class="mr-6 text-base icon-list text-gray"></span>
					<h4 class="text-base font-semibold text-gray">DOCUMENT LANGUAGE</h4>
				</div>
				<div class="flex gap-3.5" :class="quotationDetailContainerWidth < 640? 'flex-col' : 'flex-row'">
					<RadioGista :modelValue="isEnglish"
						:nativeValue="false"
						@change="changeLanguage"
						class="mr-3 text-gray">BAHASA INDONESIA</RadioGista>
					<RadioGista :modelValue="isEnglish"
						:nativeValue="true"
						@change="changeLanguage"
						class="text-gray">ENGLISH</RadioGista>
				</div>
			</div>
			<div class="flex justify-end w-full" v-if="menuRfqDetailsSendRfq?.canUpdate && statusApproved && !someInProgress">
				<SendRFQ :isEnglish="isEnglish"/>
			</div>

		</div>
		<DetailLoading v-else/>
	</div>
</template>
<script>
import QuotationInformation from '@/core/components/rfq/detail/QuotationInformation.vue'
import ItemList from '@/core/components/rfq/detail/ItemList.vue'
import SendRFQ from '@/core/components/rfq/modal/SendRFQ'
import { SPV_ITP_01_APPROVED } from '@/core/constant/statusProject'
import DetailLoading from '@/core/components/rfq/loading/DetailLoading.vue'

export default {
	name: "DetailQuotation",
	data() {
		return {
			isEnglish: false,
      isLoading: false,
		}
	},
	computed: {
		menuRfqDetailsSendRfq() {
			return this.$store.getters['dashboard/menuRfqDetailsSendRfq']
		},
		statusApproved() {
			const statusProject = [SPV_ITP_01_APPROVED]
      return statusProject.includes(this.currentStatusProject)
		},
		projectLogRfq() {
			return this.$store.state.projectLog.projectLogRfq
		},
		someInProgress() {
			return this.projectLogRfq?.rfqLogs?.some(e => e.isInProgress || e.additionalLogs?.some(el => el.isInProgress))
		},
		quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
		currentStatusProject() {
      if (this.projectLogRfq?.rfqLogs.length > 0) {
				return this.projectLogRfq?.rfqLogs[this.projectLogRfq?.rfqLogs.length - 1]?.statusProject
			}
			return false
    },
		loading: {
			get() {
				return this.$store.state.modal.loading
			},
			set(value) {
				this.$store.commit('modal/SET_LOADING', value)
			}
		},
	},
	methods: {
		changeLanguage(e) {
			console.log(e, 'language');
			this.isEnglish = !this.isEnglish
		}
	},
	async mounted() {
		this.isLoading = true
    this.loading.loadingProjectLog = true
		const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.quotationId, projectRequestType: 'rfq' })
		console.log(res, res?.data.code, 'get access');
    if (res?.data.code !== 200) return
		const r1 = this.$store.dispatch('quotationDetail/getQuotationInformation', this.$route.params.quotationId)
		const r2 = this.$store.dispatch('projectLog/getProjectLogByRfqId', { rfqId: this.$route.params.quotationId })
		const [res1, res2] = await Promise.all([r1, r2])
		if (res1?.status === 200) {
			this.isLoading = false
		}
		if (res2?.status === 200) {
      this.loading.loadingProjectLog = false
			if (this.someInProgress) {
				this.$store.dispatch('projectLog/refetchProjectLogByRfqId', this.$route.params.quotationId)
			}
		}

	},
  components: {
    QuotationInformation,
    ItemList,
    SendRFQ,
    DetailLoading,
	}
}
</script>