<template>
	<div class="">
		<div class="w-full overflow-auto max-h-custom">
			<div class="w-full">
				<table class="relative w-full border-collapse" aria-describedby="costBudgetInformation">
					<thead class="sticky top-0 z-20 h-10 py-1 bg-white">
						<tr class="truncate">
							<th v-if="edit" class="p-0" scope="col">
								<h5 class="h-10 border-b-2 border-primary"></h5>
							</th>
							<th class="p-0" scope="col" >
								<h5 class="flex items-center min-w-80 h-10 pr-12 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary px-2.5">Pos Budget Name</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center min-w-24 h-10 pr-12 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary px-3.5">RCC</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center min-w-80 h-10 pr-12 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary px-3.5">WBS Lev 3 Code</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center min-w-40 h-10 pr-12 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary px-3.5">WBS Type</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center min-w-32 h-10 pr-12 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary px-3.5">COA</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center min-w-32 h-10 pr-12 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary px-3.5">Category</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center min-w-52 h-10 pr-12 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary px-3.5">Budget Value (IDR)</h5>
							</th>
						</tr>
					</thead>
					<tbody>
            <tr v-for="n,i in data" :key="i" class="truncate border-b border-gray-lightest">
              <td class="w-7" v-if="edit">
                <div class="flex items-center px-1">
                  <p class="text-xl cursor-pointer icon-x text-error" @click="removeRow(i)" ></p>
                </div>
              </td>
              <td class="pr-3.5 pl-2.5 py-2 align-top">
                <div v-if="edit">
                  <InputExtraSmall 
                    :widthFull="true" 
                    v-model="n.posBudgetName" 
                    :error="!!n.error['Pos Budget Name']" 
                    @handleInput="inputString($event, 'posBudgetName', 'Pos Budget Name', i)"
                    bgColor="bg-white" 
                    textAlign="left">
                    <template #message v-if="!!n.error['Pos Budget Name']">
                      <p>{{ n.error['Pos Budget Name'] }}</p>
                    </template>
                  </InputExtraSmall>
                </div>
                <div v-else>
								  <h5 class="pr-12 text-sm font-medium text-left text-gray-darkest">{{  n.posBudgetName }}</h5>
                </div>
              </td>
              <td class="px-3.5 py-2 align-top">
                <div v-if="edit">
                  <InputExtraSmall 
                    :widthFull="true" 
                    v-model="n.rcc" 
                    :error="!!n.error['RCC']" 
                    @keypress="validationNumber"
                    @handleInput="inputRcc($event, 'RCC', i)"
                    bgColor="bg-white" 
                    textAlign="left">
                    <template #message v-if="!!n.error['RCC']">
                      <p>{{ n.error['RCC'] }}</p>
                    </template>
                  </InputExtraSmall>
                </div>
                <div v-else>
								  <h5 class="pr-12 text-sm font-medium text-left text-gray-darkest">{{  n.rcc }}</h5>
                </div>
              </td>
              <td class="px-3.5 py-2 align-top">
                <div v-if="edit">
                  <InputExtraSmall 
                    :widthFull="true" 
                    v-model="n.wbsLev3Code" 
                    :error="!!n.error['WBS Level 3 Code']" 
                    @handleInput="inputString($event, 'wbsLev3Code', 'WBS Level 3 Code', i)"
                    bgColor="bg-white" 
                    textAlign="left">
                    <template #message v-if="!!n.error['WBS Level 3 Code']">
                      <p>{{ n.error['WBS Level 3 Code'] }}</p>
                    </template>
                  </InputExtraSmall>
                </div>
                <div v-else>
								  <h5 class="pr-12 text-sm font-medium text-left text-gray-darkest">{{  n.wbsLev3Code }}</h5>
                </div>
              </td>
              <td class="px-3.5 py-2 align-top">
                <div v-if="edit">
                  <SelectSolutip label=""
                    size="extra-small"
                    placeholder="(pilih)"
                    :options="['Otomatis', 'Tampungan']"
                    :errors="!!n.error['WBS Type']"
                    @change="e => changeWbs(e, i)">
                    <template #selected>
                      <p class="text-gray">{{n.wbsType || '(pilih)'}}</p>
                    </template>
                  </SelectSolutip>
                  <div v-if="!!n.error['WBS Type']">
                    <p class="mt-1 text-xs text-error">{{ n.error['WBS Type'] }}</p>
                  </div>
                </div>
                <div v-else>
								  <h5 class="pr-12 text-sm font-medium text-left text-gray-darkest">{{  n.wbsType }}</h5>
                </div>
              </td>
              <td class="px-3.5 py-2 align-top">
                <div v-if="edit">
                  <InputExtraSmall 
                    :widthFull="true"
                    v-model="n.coa" 
                    :error="!!n.error['COA']" 
                    @handleInput="inputString($event, 'coa', 'COA', i)"
                    bgColor="bg-white" 
                    textAlign="left">
                    <template #message v-if="!!n.error['COA']">
                      <p>{{ n.error['COA'] }}</p>
                    </template>
                  </InputExtraSmall>
                </div>
                <div v-else>
								  <h5 class="pr-12 text-sm font-medium text-left text-gray-darkest">{{  n.coa }}</h5>
                </div>
              </td>
              <td class="px-3.5 py-2 align-top">
                <div v-if="edit">
                  <SelectSolutip label=""
                    size="extra-small"
                    placeholder="(pilih)"
                    :options="['Aktiva', 'Bayaran']"
                    :errors="!!n.error['Category']"
                    @change="e => changeCategory(e, i)">
                    <template #selected>
                      <p class="text-gray">{{n.category || '(pilih)'}}</p>
                    </template>
                  </SelectSolutip>
                  <div v-if="!!n.error['Category']">
                    <p class="mt-1 text-xs text-error">{{ n.error['Category'] }}</p>
                  </div>
                </div>
                <div v-else>
                  <h5 class="pr-12 text-sm font-medium text-left text-gray-darkest">{{  n.category }}</h5>
                </div>
              </td>
              <td class="px-3.5 py-2 align-top">
                <div v-if="edit">
                  <InputExtraSmall 
                    :widthFull="true" 
                    v-model="n.budgetValue" 
                    @keypress="validationNumber"
                    @input="inputBudgetValue($event, i)"
                    @handleInput="handleInputBudgetValue($event, i)"
                    :error="!!n.error['Budget Value']" 
                    bgColor="bg-white" 
                    textAlign="left">
                    <template #message v-if="!!n.error['Budget Value']">
                      <p>{{ n.error['Budget Value'] }}</p>
                    </template>
                  </InputExtraSmall>
                </div>
                <div v-else>
								  <h5 class="pr-12 text-sm font-medium text-left text-gray-darkest">{{  formatCurrency(n.budgetValue) }}</h5>
                </div>
              </td>
            </tr>
            <tr v-if="edit" class="h-10 truncate border-b border-gray-lightest">
              <td class="pl-2.5" colspan="8">
                <p class="flex items-center h-10 text-sm font-medium text-left underline cursor-pointer text-secondary " @click="addRow()">Add</p>
              </td>
            </tr>
            <tr class="border-b-2 bg-gradient-to-t border-primary from-primary-lightest to-white">
              <td class="p-0" colspan="8">
                <div class="flex items-center h-10 ">
                  <p class="flex items-center h-10 text-sm font-medium text-left text-gray-darkest pl-2.5 pr-3.5">Total Budget:</p>
                  <p class="flex items-center h-10 text-sm font-medium text-left text-success-dark">{{ formatCurrency(totalBudget()) }}</p>
                </div>
              </td>
            </tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { formatCurrency } from '@/core/components/helpers/formatRupiah'
import InputExtraSmall from '@/core/components/custom/InputExtraSmall.vue'
import SelectSolutip from "@/core/components/custom/Select.vue";
import { formatThousandSeparate } from '@/core/components/helpers/formatRupiah'

export default {
	name: 'CostBudgetInformation',
  props: {
    edit: {
      type: Boolean,
      default: true
    }
  },
	data() {
		return {
		}
	},
  computed: {
    data: {
      get() {
        return this.$store.state.procurementApprovalRfq.dataCostBudgetInformation
      },
      set(val) {
        this.$store.commit('procurementApprovalRfq/SET_DATA_COST_BUDGET_INFORMATION', val)
      }
    }
  },
	methods: {
    formatCurrency,
    changeWbs(e, i) {
      console.log(e,i);
      this.data = this.data.map((el, idx) => idx === i? ({ ...el, wbsType: e, error: { ...el.error, 'WBS Type': '' } }) : el )
    },
    changeCategory(e, i) {
      console.log(e, i);
      this.data = this.data.map((el, idx) => idx === i? ({ ...el, category: e, error: { ...el.error, 'Category': '' } }) : el )
    },
    removeRow(i) {
      console.log(i);
      this.data = this.data.filter((e,idx) => idx !== i)
    },
    addRow() {
      this.data = [ ...this.data, {
					posBudgetName: '',
					rcc: '',
					wbsLev3Code: '',
					wbsType: '',
          coa: '',
					category: '',
          budgetValue: '',
          error: {
            "Pos Budget Name": '',
            "RCC": '',
            "WBS Level 3 Code": '',
            "WBS Type": '',
            "COA": '',
            "Category": '',
            "Budget Value": '',
            }
				},]
    },
    totalBudget() {
      return this.data.reduce((a,b) => +a + +b.budgetValue, 0)
    },
    handleCheckLength(e, name, i) {
      const map = {
        "Pos Budget Name": { lengthMax: 200, message: 'Maksimal 200 karakter' },
        "RCC": { lengthMax: 10, message: 'Maksimal 10 digit' },
        "WBS Level 3 Code": { lengthMax: 100, message: 'Maksimal 100 karakter' },
        "COA": { lengthMax: 20, message: 'Maksimal 20 karakter'},
        "Budget Value": { lengthMax: 14, message: 'Maksimal 14 digit' },
      }
      if (e.length > map[name].lengthMax) this.data[i].error[name] = map[name].message
      else if (e.length < 1) this.data[i].error[name] = 'Wajib Diisi'
      else this.data[i].error[name] = ''
    },
    validationNumber(e) {
      let keyCode = e.keyCode
			if ((keyCode > 47 && keyCode < 58)) {
				return
			}
			e.preventDefault();
    },
    inputString(e,key, name, i) {
      this.handleCheckLength(e.target.value, name, i)
    },
    inputRcc(e, name, i) {
      const inputValue = e.target.value
      this.data = this.data.map((el, idx) => i === idx? ({ ...el, rcc: !isNaN(+inputValue) ? inputValue : '' }) : el) 
      this.handleCheckLength(inputValue, name, i)
    },
    inputBudgetValue(e, i) {
      const plainNumber = e.split('.').join('')
      this.data = this.data.map((el, idx) => i === idx? ({ ...el, budgetValue: !isNaN(+plainNumber) ? formatThousandSeparate(plainNumber, 'idr') : '' }) : el) 
      this.handleCheckLength(plainNumber, 'Budget Value', i)
    },
    handleInputBudgetValue(e) {
      const el = e.target;
			const formatted = formatThousandSeparate(el.value.split('.').join(''), 'idr')
			const count = formatted?.split('.').length
			const count2 = el.value?.split('.').length
			let sel = count > count2 ? el.selectionStart + 1 : count < count2 ? el.selectionStart - 1 : el.selectionStart
      this.$nextTick(() => {
        el.setSelectionRange(sel, sel);
      });
		},
	},
  components: {
    InputExtraSmall,
    SelectSolutip,
  }
}
</script>
<style scoped>
.max-h-custom {
	max-height: 40rem;
}
.min-w-24 {
  min-width: 6rem;
}
.min-w-32 {
  min-width: 8rem;
}
.min-w-40 {
  min-width: 10rem;
}
.min-w-52 {
  min-width: 13rem;
}
.min-w-80 {
  min-width: 20rem;
}

</style>
