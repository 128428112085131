<template>
  <div class="w-full">
    <div class="flex items-start w-full my-6" :class="projectDetailContainerWidth < 768 ? 'gap-2.5 flex-col' : 'justify-between'">
      <h4 class="ml-2 text-lg font-medium text-gray-darkest">Procurement Approval</h4>
      <div :class="projectDetailContainerWidth < 640 ? 'flex flex-col w-full mt-3' : 'flex flex-row'" class="gap-6">
        <SecurityCode />
        <ButtonGista size="small" type="secondary" color="primary-darkest" class="text-sm font-semibold" :class="projectDetailContainerWidth < 640 ? 'w-full' : 'w-fit'">
          <template #icon-left>
            <span class="icon-download text-2xl pr-3.5"></span>
          </template>
          Download
        </ButtonGista>
      </div>
    </div>
    <TableApprovalProcurement />
    <div class="flex justify-end w-full mt-6 mb-6 ">
      <button @click="expandCollapseAll" class="flex items-center underline cursor-pointer text-tertiary hover:text-tertiary-dark">{{messageCollapseAll}}</button>
    </div>
    <Information :readOnly="readOnly" @changeCollapse="changeCollapse('information')" :collapse="collapse" />
    <NegotiationResult :readOnly="readOnly" @changeCollapse="changeCollapse('negoResults')" :collapse="collapse" />
    <div class="w-full pt-6 mt-6 border-t-2 border-gray-lightest">
      <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse('vendorCandidateScoring')" >
        <div class="flex items-center">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">VENDOR CANDIDATES & SCORING &nbsp;</h5>
        </div>
        <div>
          <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse.vendorCandidateScoring ? 'rotate-180' : 'rotate-0'"></p>
        </div>
      </div>
     <div v-show="collapse.vendorCandidateScoring">
      <VendorCandidateScoringTable :edit="false"/>
      <div class="col-span-2 py-3.5">
          <TextareaGista label="Reason for Vendor Selection"
            disabled
            placeholder="Leave a comment here"
            value="text"
            >
          </TextareaGista>
        </div>
      </div>
    </div>
    <div class="w-full pt-6 mt-6 border-t-2 border-gray-lightest">
      <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse('projectApproval')">
        <div class="flex items-center">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">PROJECT PROCUREMENT APPROVAL</h5>
        </div>
        <div>
          <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse.projectApproval ? 'rotate-180' : 'rotate-0'"></p>
        </div>
      </div>
    </div>
    <ProjectProcurementApprovalTable v-show="collapse.projectApproval" />
    <div class="w-full pt-6 mt-6 border-t-2 border-gray-lightest">
      <div class="flex items-center justify-between mb-3 cursor-pointer" @click="changeCollapse('costBudget')">
        <div class="flex items-center">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">COST & BUDGET INFORMATION &nbsp;</h5>
        </div>
        <div>
          <p class="text-2xl transition ease-in transform icon-chevron-down text-gray" :class="collapse.costBudget ? 'rotate-180' : 'rotate-0'"></p>
        </div>
      </div>
    </div>
    <CostBudgetInformation v-show="collapse.costBudget" :edit="false"/>
    <Recommendation :readOnly="readOnly" @changeCollapse="changeCollapse" :collapse="collapse" />
    <UploadSignedApproval @changeCollapse="changeCollapse('uploadSigned')" :collapse="collapse" />
  </div>
</template>

<script>

import NegotiationResult from '@/core/components/rfq/procurementApproval/NegotiationResult.vue'
import VendorCandidateScoringTable from '@/core/components/rfq/procurementApproval/VendorCandidateScoringTable.vue'
import Recommendation from '@/core/components/rfq/procurementApproval/Recommendation.vue'
import Information from '@/core/components/rfq/procurementApproval/Information.vue'
import ProjectProcurementApprovalTable from '@/core/components/rfq/procurementApproval/ProjectProcurementApprovalTable.vue'
import CostBudgetInformation from '@/core/components/rfq/procurementApproval/CostBudgetInformation.vue'
import TableApprovalProcurement from '@/core/components/rfq/procurementApproval/TableApprovalProcurement.vue'
import SecurityCode from '@/core/components/rfq/procurementApproval/SecurityCode'
import UploadSignedApproval from '@/core/components/rfq/procurementApproval/UploadSignedApproval'

export default {
  name: "ProcurementApprovalDetails",
  data() {
    return {
      messageCollapseAll: 'Collapse All',
      collapse: {
        information: true,
        negoResults: true,
        vendorCandidateScoring: true,
        projectApproval: true,
        costBudget: true,
        recommendation: true,
        checker: true,
        approver: true,
        uploadSigned: true
      },
      readOnly: true
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
  },
  methods:{
    //function collapse/extend
    changeCollapse(key) {
      this.collapse[key] = !this.collapse[key]
      if (Object.values(this.collapse).every(el => el)) this.messageCollapseAll = 'Collapse All'
      else if (Object.values(this.collapse).every(el => !el)) this.messageCollapseAll = 'Extend All'
    },
    expandCollapseAll() {
      for (const key in this.collapse) {
        if (this.messageCollapseAll === 'Extend All') {
          this.collapse[key] = true
        } else {
          this.collapse[key] = false
        }
      }
      if (this.messageCollapseAll === 'Collapse All') this.messageCollapseAll = 'Extend All'
      else this.messageCollapseAll = 'Collapse All'
    },
    changeIsCreateProcurementApproval() {
      this.$emit('changeIsCreateProcurementApproval')
    },
    clickPreviewBan() {
      console.log('clickPreviewBan')
    },
  },
  components:{
    Information,
    NegotiationResult,
    VendorCandidateScoringTable,
    Recommendation,
    ProjectProcurementApprovalTable,
    CostBudgetInformation,
    TableApprovalProcurement,
    SecurityCode,
    UploadSignedApproval
  }
}
</script>

